import React, { useEffect, useMemo, useRef } from 'react'

import { CANVAS_DATA_TYPE, CANVAS_TYPE, ICanvas } from './types'
import {
  canvasStyles,
  dropAreaStyles,
  fitAreaStyles,
  innerStyles,
} from './styles'
import { useCanvasScale } from './hooks'
import { CanvasDnD } from './CanvasDnD'
import { CanvasStatic } from './CanvasStatic'
import { DragPreview } from './components'
import {
  useActiveColorMap,
  useActiveCanvas,
  useActiveThemeColors,
  useFlags,
  useGradientColor,
  useRectObserver,
  usePromiseCanvas,
} from 'src/hooks'
import { CanvasSideBar } from './components/canvas-side-bar'
import { CanvasCover } from './components/canvas-cover'
import { colorVariableToCssVariable } from 'src/services/colorServices'
import { CanvasRectEvent } from 'src/events'

export const Canvas: React.FC<ICanvas> = React.memo(
  ({
    canvasType = CANVAS_TYPE.STATIC,
    dataType = CANVAS_DATA_TYPE.ACTIVE_SLIDE,
    dataProps,
    external,
    className,
  }) => {
    const activeCanvasData = useActiveCanvas({
      slideDataId: dataProps?.slideDataId,
    })

    const promiseCanvasData = usePromiseCanvas({
      deckId: dataProps?.deckId,
      shareKey: dataProps?.shareKey,
      orderIndex: dataProps?.orderIndex,
      deck: dataProps?.deck,
      slideData: dataProps?.slideData,
    })

    const endComponents = useMemo(() => {
      switch (dataType) {
        case CANVAS_DATA_TYPE.ACTIVE_SLIDE:
          return activeCanvasData.components
        case CANVAS_DATA_TYPE.ACTIVE_DECK:
          return activeCanvasData.components
        case CANVAS_DATA_TYPE.DECK:
          return promiseCanvasData.components
        default:
          return external?.components
      }
    }, [
      dataType,
      activeCanvasData.components,
      promiseCanvasData.components,
      external?.components,
    ])

    const tempSvgUrl = useMemo(() => {
      switch (dataType) {
        case CANVAS_DATA_TYPE.ACTIVE_SLIDE:
          return activeCanvasData.tempSvgUrl
        case CANVAS_DATA_TYPE.ACTIVE_DECK:
          return activeCanvasData.tempSvgUrl
        case CANVAS_DATA_TYPE.DECK:
          return promiseCanvasData.tempSvgUrl
        default:
          return external?.svgUrl
      }
    }, [
      dataType,
      activeCanvasData.tempSvgUrl,
      promiseCanvasData.tempSvgUrl,
      external?.svgUrl,
    ])

    const isSwapped = useMemo(() => {
      switch (dataType) {
        case CANVAS_DATA_TYPE.ACTIVE_SLIDE:
          return activeCanvasData.isSwapped
        case CANVAS_DATA_TYPE.ACTIVE_DECK:
          return activeCanvasData.isSwapped
        case CANVAS_DATA_TYPE.DECK:
          return promiseCanvasData.isSwapped
        default:
          return external?.isSwapColor
      }
    }, [
      dataType,
      activeCanvasData.isSwapped,
      promiseCanvasData.isSwapped,
      external?.isSwapColor,
    ])

    const backgroundColor = useMemo(() => {
      switch (dataType) {
        case CANVAS_DATA_TYPE.ACTIVE_SLIDE:
          return activeCanvasData.backgroundColor
        case CANVAS_DATA_TYPE.ACTIVE_DECK:
          return activeCanvasData.backgroundColor
        case CANVAS_DATA_TYPE.DECK:
          return promiseCanvasData.backgroundColor
        default:
          return external?.background
      }
    }, [
      dataType,
      activeCanvasData.backgroundColor,
      promiseCanvasData.backgroundColor,
      external?.background,
    ])

    const endSvgDecorString = useMemo(() => {
      switch (dataType) {
        case CANVAS_DATA_TYPE.ACTIVE_SLIDE:
          return activeCanvasData.svgDecorString
        case CANVAS_DATA_TYPE.ACTIVE_DECK:
          return activeCanvasData.svgDecorString
        case CANVAS_DATA_TYPE.DECK:
          return promiseCanvasData.svgDecorString
        default:
          return external?.svgDecor
      }
    }, [
      dataType,
      activeCanvasData.svgDecorString,
      promiseCanvasData.svgDecorString,
      external?.svgDecor,
    ])

    const endCssVariables = useMemo(() => {
      switch (dataType) {
        case CANVAS_DATA_TYPE.ACTIVE_SLIDE:
          return activeCanvasData.cssVariables
        case CANVAS_DATA_TYPE.ACTIVE_DECK:
          return activeCanvasData.cssVariables
        case CANVAS_DATA_TYPE.DECK:
          return promiseCanvasData.cssVariables
        default:
          return external?.cssVariables
      }
    }, [
      dataType,
      activeCanvasData.cssVariables,
      promiseCanvasData.cssVariables,
      external,
    ])

    const flags = useFlags()
    const { asBackground } = useGradientColor(backgroundColor || undefined)

    const editAreaRef = useRef<HTMLDivElement>(null)
    const dropAreaRef = useRef<HTMLDivElement>(null)
    const activeThemeColors = useActiveThemeColors()

    const colorMap = useActiveColorMap({
      providedDefaultColorMap: dataProps?.defaultColorMap,
      providedColorMap: dataProps?.colorMap,
    })
    const scale = useCanvasScale(editAreaRef)

    const editAreaRect = useRectObserver(editAreaRef)
    const dropAreaRect = useRectObserver(dropAreaRef)

    const toolbarPos = useMemo(() => {
      const dropAreaWidth = (dropAreaRect?.width || 1) * scale
      const dropAreaHeight = (dropAreaRect?.height || 1) * scale
      const leftSpace = ((editAreaRect?.width || 0) - dropAreaWidth) / 2

      return { left: leftSpace + dropAreaWidth, height: dropAreaHeight }
    }, [editAreaRect, dropAreaRect])

    const decorationMap = useMemo(() => {
      if (flags.FE_604_TEMPLATES_DECORS_FONTS) {
        return {
          first: colorVariableToCssVariable({
            value:
              dataProps?.deck?.deckData?.themeColor.data.first ??
              colorMap?.decorations[0]?.[isSwapped ? 'swap' : 'default']
                ?.colors?.[0],
          }),
          second: colorVariableToCssVariable({
            value:
              dataProps?.deck?.deckData?.themeColor.data.second ??
              colorMap?.decorations[1]?.[isSwapped ? 'swap' : 'default']
                ?.colors?.[0],
          }),
          third: colorVariableToCssVariable({
            value:
              dataProps?.deck?.deckData?.themeColor.data.third ??
              colorMap?.decorations[2]?.[isSwapped ? 'swap' : 'default']
                ?.colors?.[0],
          }),
          fourth: colorVariableToCssVariable({
            value:
              dataProps?.deck?.deckData?.themeColor.data.fourth ??
              colorMap?.decorations[3]?.[isSwapped ? 'swap' : 'default']
                ?.colors?.[0],
          }),
        }
      } else {
        return {
          first: colorVariableToCssVariable({
            value:
              (colorMap?.decorations &&
                colorMap?.decorations[0]?.[isSwapped ? 'swap' : 'default']
                  ?.colors?.[0]) ??
              activeThemeColors?.first,
          }),
          second: colorVariableToCssVariable({
            value:
              (colorMap?.decorations &&
                colorMap?.decorations[1]?.[isSwapped ? 'swap' : 'default']
                  ?.colors?.[0]) ??
              activeThemeColors?.second,
          }),
          third: colorVariableToCssVariable({
            value:
              (colorMap?.decorations &&
                colorMap?.decorations[2]?.[isSwapped ? 'swap' : 'default']
                  ?.colors?.[0]) ??
              activeThemeColors?.third,
          }),
          fourth: colorVariableToCssVariable({
            value:
              (colorMap?.decorations &&
                colorMap?.decorations[3]?.[isSwapped ? 'swap' : 'default']
                  ?.colors?.[0]) ??
              activeThemeColors?.fourth,
          }),
        }
      }
    }, [
      dataProps?.deck?.deckData,
      colorMap?.decorations,
      isSwapped,
      flags.FE_604_TEMPLATES_DECORS_FONTS,
    ])

    useEffect(() => {
      if (canvasType === CANVAS_TYPE.DND) {
        const rect = dropAreaRef.current?.getBoundingClientRect()

        const event = new CanvasRectEvent({ rect, scale })
        document.dispatchEvent(event)
      }
    }, [canvasType, dropAreaRef.current?.getBoundingClientRect(), scale])

    return (
      <div
        style={{ width: '100%', height: '100%' }}
        css={{ endCssVariables }}
        className={`${isSwapped ? `theme-swapped` : `theme-default`}`}
      >
        {canvasType === CANVAS_TYPE.DND && <DragPreview scale={scale} />}
        <div
          css={canvasStyles({ canvasType })}
          className={className}
          ref={editAreaRef}
        >
          <div css={fitAreaStyles} data-canvas={true}>
            <div css={innerStyles}>
              <div
                css={dropAreaStyles({ scale })}
                ref={dropAreaRef}
                data-font-family-exception="true"
                style={{ ...asBackground }}
              >
                {canvasType === CANVAS_TYPE.DND ? (
                  <CanvasDnD components={endComponents} scale={scale} />
                ) : (
                  <CanvasStatic
                    canvasType={canvasType}
                    components={endComponents}
                    scale={scale}
                    slideDataId={dataProps?.slideDataId}
                  />
                )}
                <div style={{ pointerEvents: 'none' }}>
                  {(tempSvgUrl || endSvgDecorString) && (
                    <CanvasCover
                      themeColors={dataProps?.themeColors ?? activeThemeColors}
                      backgroundColor={backgroundColor}
                      url={tempSvgUrl}
                      isSwapColor={isSwapped}
                      svgDecorString={endSvgDecorString}
                      svgDecorColorMap={{
                        ...decorationMap,
                        bg: backgroundColor,
                      }}
                    />
                  )}
                </div>
              </div>
              {canvasType === CANVAS_TYPE.DND && (
                <CanvasSideBar pos={toolbarPos} scale={scale} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  },
)

Canvas.displayName = 'Canvas'
