export const APP_CONFIG = {
  debug: false,
  requestRetry: {
    count: 3,
    delay: 1000,
  },
  authTokenExpireTime: 60 * 60,
  fileUploadList: [
    { ext: '.jpg', mime: 'image/jpeg', format: 'jpg' },
    { ext: '.jpeg', mime: 'image/jpeg', format: 'jpeg' },
    { ext: '.png', mime: 'image/png', format: 'png' },
    { ext: '.gif', mime: 'image/gif', format: 'gif' },
    { ext: '.svg', mime: 'image/svg+xml', format: 'svg' },
  ],
  canvasRatio: 16 / 9,
  canvasMasterWidth: 1920,
  editSaveTime: 5000,
  idleTimeout: 60 * 60 * 1000, // 1hour
  canvasHorGridCount: 50,
  canvasGridWidth: 1920 / 50,
  canvasBaseFontSize: 4,
  copyButtonTimeout: 2000,
  canvasTextAllowedFormats: [
    'bold',
    'italic',
    'link',
    'underline',
    'list',
    'highlight',
    'clean',
    'span',
  ],
  editPage: {
    autoFixTimeout: 30 * 1000, // 30 seconds
    showToolbar: false,
    scaling: {
      minValue: 30,
    },
    fontSizeOptions: [
      {
        name: '8',
        value: '8em',
      },
      {
        name: '10',
        value: '10em',
      },
      {
        name: '12',
        value: '12em',
      },
      {
        name: '16',
        value: '16em',
      },
      {
        name: '24',
        value: '24em',
      },
      {
        name: '30',
        value: '30em',
      },
      {
        name: '32',
        value: '32em',
      },
      {
        name: '64',
        value: '64em',
      },
      {
        name: '48',
        value: '48em',
      },
      {
        name: '72',
        value: '72em',
      },
    ],

    component: {
      list: {
        media: {
          bigVersionCount: 5,
        },
      },
    },
  },
  decksPerPage: 10,
  features: {
    startWithATemplate: false,
  },
  chatWidget: {
    disabled: ['/share', '/export', '/preview'],
  },
  pdfImportAllowedSize: 53000000,
  aiDeckInterval: 10000,
  uploadMediaAllowedSize: 5200000,
  hCaptchaLocalKey: '10000000-ffff-ffff-ffff-000000000001',
  aiLoadingCompleteTimeout: 1300,
}
