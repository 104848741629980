import React from 'react'
import CacheBuster from 'react-cache-buster'
import AuthProvider from 'src/provider/authProvider'
import Routes from 'src/routes'
import { ThemeProvider } from '@emotion/react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from 'src/store'
import { theme } from './theme'
import './index.css'
import './iziToast.css'
import './quill.css'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from 'src/store'
import { HelmetProvider } from 'react-helmet-async'
import { PostHogProviderWrapper } from 'src/provider'
import packageInfo from '../package.json'

const isProduction = process.env.NODE_ENV === 'production'

export const App: React.FC = () => {
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction}
      isVerboseMode={false}
    >
      <PostHogProviderWrapper>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <AuthProvider>
                  <DndProvider backend={HTML5Backend}>
                    <Routes />
                  </DndProvider>
                </AuthProvider>
              </PersistGate>
            </ReduxProvider>
          </ThemeProvider>
        </HelmetProvider>
      </PostHogProviderWrapper>
    </CacheBuster>
  )
}

export default App
