import { ErrorDefinitions } from 'src/types/api/ErrorDefinitions'

type IErrorDefinitions = {
  [key in ErrorDefinitions]?: string
}

export const errors: IErrorDefinitions = {
  [ErrorDefinitions.VALIDATION_EMAIL_MALFORMED]: 'Ungültige E-Mail-Adresse',
  [ErrorDefinitions.VALIDATION_PASSWORD_LENGTH]:
    'Das Passwort muss mindestens 6 Zeichen lang sein.',
  [ErrorDefinitions.AICREDITREASONDEFAULTVALUE_NOT_FOUND]:
    'AI-Credit-Wert nicht gefunden',
  [ErrorDefinitions.API_ERROR]: 'API-Fehler',
  [ErrorDefinitions.DATE_ERROR]: 'Ungültiges Datumsformat',
  [ErrorDefinitions.INCORRECT_PROVIDER]:
    'Bitte verwenden Sie für dieses Konto die Google-Anmeldung',
  [ErrorDefinitions.KEY_NOT_FOUND]: 'Schlüssel nicht gefunden',
  [ErrorDefinitions.NO_ORG_ACCESS]: 'Kein Organisationszugriff',
  [ErrorDefinitions.NO_TEAM_ACCESS]: 'Kein Teamzugriff',
  [ErrorDefinitions.PLAN_NOT_FOUND]: 'Plan nicht gefunden',
  [ErrorDefinitions.ACTIVE_PAYMENT_IN_PROGRESS]:
    'Aktive Zahlung in Bearbeitung',
  [ErrorDefinitions.PLAN_IS_NOT_AVAILABLE]:
    'Der ausgewählte Plan ist nicht verfügbar',
  [ErrorDefinitions.PLAN_PRODUCT_NOT_FOUND]: 'Planprodukt nicht gefunden',
  [ErrorDefinitions.PRICE_NOT_FOUND]: 'Preis nicht gefunden',
  [ErrorDefinitions.USER_NOT_FOUND]: 'Benutzer nicht gefunden',
  [ErrorDefinitions.USER_PLAN_NOT_FOUND]: 'Benutzerplan nicht gefunden',
  [ErrorDefinitions.USER_EXIST]: 'E-Mail-Adresse existiert bereits',
  [ErrorDefinitions.WRONG_PASSWORD]: 'Falsches Passwort',
  [ErrorDefinitions.USER_NOT_VERIFIED]: 'Benutzer nicht verifiziert',
  [ErrorDefinitions.REFERRED_USER_EXIST]:
    'Der empfohlene Benutzer existiert bereits',
  [ErrorDefinitions.CAN_NOT_PURCHASE]: 'Kauf nicht möglich',
  [ErrorDefinitions.CAN_NOT_UPGRADE]: 'Upgrade nicht möglich',
  [ErrorDefinitions.SUBSCRIPTION_NOT_FOUND]: 'Abonnement nicht gefunden',
  [ErrorDefinitions.SUBSCRIPTION_ITEM_NOT_FOUND]:
    'Abonnementartikel nicht gefunden',
  [ErrorDefinitions.SUBSCRIPTION_CHANGE_NOT_FOUND]:
    'Abonnementänderung nicht gefunden',
  [ErrorDefinitions.SUBSCRIPTION_IS_NOT_ACTIVE]: 'Abonnement nicht aktiv',
  [ErrorDefinitions.UNKNOWN_EVENT_TYPE]: 'Unbekannter Ereignistyp',
  [ErrorDefinitions.TEAM_NOT_FOUND]: 'Team nicht gefunden',
  [ErrorDefinitions.ORG_NOT_FOUND]: 'Organisation nicht gefunden',
  [ErrorDefinitions.ACCESS_NOT_FOUND]: 'Zugang nicht gefunden',
  [ErrorDefinitions.USER_ALREADY_EXISTS_IN_THIS_ORGANIZATION]:
    'Der Benutzer existiert bereits in dieser Organisation',
  [ErrorDefinitions.USERS_ALREADY_EXISTS_IN_THIS_TEAM]:
    'In diesem Team sind bereits Benutzer vorhanden',
  [ErrorDefinitions.INVITATION_ALREADY_EXISTS]:
    'Die Einladung existiert bereits',
  [ErrorDefinitions.INVITATION_NOT_FOUND]: 'Einladung nicht gefunden',
  [ErrorDefinitions.ORGANIZATION_NOT_FOUND]: 'Organisation nicht gefunden',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_DOWNGRADE]:
    'Fehler bei der Reduzierung der Sitzgröße der Organisation',
  [ErrorDefinitions.AI_CREDIT_REASON_NOT_DEFINED]:
    'AI-Kreditgrund nicht definiert',
  [ErrorDefinitions.INSUFFICIENT_AI_CREDIT]: 'Nicht genügend KI-Credits',
  [ErrorDefinitions.WEBHOOK_EXISTS]: 'Webhook existiert bereits',
  [ErrorDefinitions.WEBHOOK_NOT_EXISTS]: 'Webhook nicht verfügbar',
  [ErrorDefinitions.WEBHOOK_NO_CHANGE]: 'Keine Webhook-Änderungen',
  [ErrorDefinitions.PASSWORD_RESET_REQUEST_EXPIRED_OR_NOT_FOUND]:
    'Die Anforderung zum Zurücksetzen des Passworts ist abgelaufen oder wurde nicht gefunden',
  [ErrorDefinitions.TEAM_IS_NOT_IN_THIS_ORGANIZATION]:
    'Das Team ist nicht in dieser Organisation',
  [ErrorDefinitions.USERS_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'In dieser Organisation wurden keine Benutzer gefunden',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_TEAM]:
    'In diesem Team wurden keine Benutzer gefunden',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'In dieser Organisation wurden keine Benutzer gefunden',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_USER]:
    'Sie können Ihren eigenen Benutzer nicht aktualisieren',
  [ErrorDefinitions.MISSING_QUESTION_TYPES]: 'Fehlende Fragetypen',
  [ErrorDefinitions.USER_ALREADY_CLASSIFIED]:
    'Der Benutzer ist bereits klassifiziert',
  [ErrorDefinitions.WRONG_VERIFICATION_CODE]:
    'Der eingegebene Code ist falsch. Bitte überprüfen Sie Ihre E-Mails.',
  [ErrorDefinitions.USER_ALREADY_VERIFIED]:
    'Der Benutzer ist bereits verifiziert',
  [ErrorDefinitions.TARGET_USER_IS_OWNER]: 'Eigentümer des Zielbenutzers',
  [ErrorDefinitions.CANNOT_REMOVE_SELF_USER]:
    'Sie können Ihren eigenen Benutzer nicht löschen',
  [ErrorDefinitions.CANNOT_REMOVE_ADMIN_USER]:
    'Sie können den Admin-Benutzer nicht löschen',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_REACHED]:
    'Die maximale Sitzplatzgröße für die Veranstaltung wurde erreicht',
  [ErrorDefinitions.THEME_NOT_FOUND]: 'Thema nicht gefunden',
  [ErrorDefinitions.SLIDELAYOUT_EXIST]:
    'Das Folienlayout ist bereits vorhanden',
  [ErrorDefinitions.SLIDELAYOUT_NOT_FOUND]: 'Folienlayout nicht gefunden',
  [ErrorDefinitions.THEMECOLOR_NOT_FOUND]: 'Designfarbe nicht gefunden',
  [ErrorDefinitions.THEMEFONT_NOT_FOUND]: 'Designschriftart nicht gefunden',
  [ErrorDefinitions.COMPONENT_NOT_FOUND]: 'Komponente nicht gefunden',
  [ErrorDefinitions.STRIPE_CUSTOMER_NOT_FOUND]: 'Stripe-Kunde nicht gefunden',
  [ErrorDefinitions.ASSET_NOT_FOUND]: 'Entität nicht gefunden',
  [ErrorDefinitions.FOLDER_NOT_FOUND]: 'Ordner nicht gefunden',
  [ErrorDefinitions.DECK_NOT_FOUND]: 'Präsentation nicht gefunden',
  [ErrorDefinitions.DECK_ALREADY_SHARED]:
    'Die Präsentation wurde bereits geteilt',
  [ErrorDefinitions.DECKSHARE_NOT_FOUND]:
    'Präsentationsfreigabe nicht gefunden',
  [ErrorDefinitions.CUSTOMDOMAIN_NOT_FOUND]:
    'Benutzerdefinierte Domäne nicht gefunden',
  [ErrorDefinitions.SHORTCODE_ALREADY_EXISTS]: 'Shortcode existiert bereits',
  [ErrorDefinitions.CUSTOMDOMAIN_ALREADY_EXISTS]:
    'Die benutzerdefinierte Domäne ist bereits vorhanden',
  [ErrorDefinitions.CUSTOMDOMAINSLUG_ALREADY_EXISTS]:
    'Der benutzerdefinierte Domain-Slug ist bereits vorhanden',
  [ErrorDefinitions.SLIDEDATA_NOT_FOUND]: 'Foliendaten nicht gefunden',
  [ErrorDefinitions.DECKDATA_NOT_FOUND]: 'Präsentationsdaten nicht gefunden',
  [ErrorDefinitions.OPENAI_MAXRETRY_REACHED]:
    'OpenAI-Höchstgrenze überschritten',
  [ErrorDefinitions.THEMECATEGORY_NOT_FOUND]: 'Themenkategorie nicht gefunden',
  [ErrorDefinitions.MINROLE_ERROR]: 'Autorisierungsfehler',
  [ErrorDefinitions.SLIDE_NOT_FOUND]: 'Folie nicht gefunden',
  [ErrorDefinitions.INVALID_OFFSET]: 'Der Offset ist falsch',
  [ErrorDefinitions.FORM_QUESTION_NOT_FOUND]: 'Formularfrage nicht gefunden',
  [ErrorDefinitions.UNKNOWN_FORMQUESTION_TYPE]: 'Unbekannter Formularfragetyp',
  [ErrorDefinitions.MODERATION_ERROR]: 'Moderationsfehler',
  [ErrorDefinitions.DECKPROMPT_NOT_FOUND]:
    'Präsentationsaufforderungen nicht gefunden',
  [ErrorDefinitions.DECKSTATE_IS_NOT_FINAL]: 'Präsentation noch nicht fertig',
  [ErrorDefinitions.THEMECOLOR_THEMEFONT_NOT_IN_SAME_THEMECATEGORY]:
    'Die Designfarbe und die Designschriftart müssen zur gleichen Designkategorie gehören',
  [ErrorDefinitions.CONTENT_TOO_LONG]: 'Der Inhalt ist zu lang',
  [ErrorDefinitions.DECK_EXPORT_IN_PROGRESS]:
    'Die Übertragung der Präsentation hat begonnen',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_PERMISSION]:
    'Sie können Ihre eigene Autorität nicht ändern',
  [ErrorDefinitions.CANNOT_UPDATE_OWNERS_PERMISSION]:
    'Sie können die Berechtigung des Organisationseigentümers nicht ändern',
  [ErrorDefinitions.USER_DECK_NOT_FOUND]:
    'Präsentationen des Benutzers nicht gefunden',
  [ErrorDefinitions.PASSWORD_RESET_REQUIRED]: 'Sie müssen Ihr Passwort ändern',
  [ErrorDefinitions.ALREADY_REFERRED]:
    'Dieser Benutzer wurde bereits einmal eingeladen',
  [ErrorDefinitions.CAPTCHA_NOT_CORRECT]:
    'Bei der Captcha-Überprüfung ist ein Fehler aufgetreten',
}
