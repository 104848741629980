import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  Button,
  INPUT_SIZE,
  INPUT_WIDTH,
  Input,
} from 'src/lib'
import {
  VALIDATION_RULE_TYPES,
  useAuthApi,
  useFlags,
  useLanguage,
  useNotification,
} from 'src/hooks'
import { actionsStyles, formInner, gradientText } from './styles'
import { IResetPasswordForm } from './types'
import { LottieButton } from '../lottie-button'
import { Captcha } from '../captcha'
import HCaptcha from '@hcaptcha/react-hcaptcha'

export const ResetPasswordForm: React.FC<IResetPasswordForm> = React.memo(
  ({ className, dataAttr }) => {
    const navigate = useNavigate()
    const { success } = useNotification()
    const [searchParams] = useSearchParams()
    const flags = useFlags()
    const { sendResetEmail, resetPassword, isLoading } = useAuthApi()
    const { t } = useLanguage()

    const [inputValid, setInputValid] = useState(false)
    const [newPassword, setInputValue] = useState('')
    const [isResetEmailSent, setIsResentEmailSent] = useState(false)
    const [shouldResetCaptcha, setShouldResetCaptcha] = useState(false)
    const [isCaptchaLoading, setIsCaptchaLoading] = useState(false)
    const captchaRef = useRef<HCaptcha>(null)

    const resetToken = useMemo(() => searchParams.get('token'), [searchParams])
    const resetEmail = useMemo(() => searchParams.get('email'), [searchParams])

    const onLoginClickHandler = useCallback(() => {
      navigate('/login', { replace: true })
    }, [])

    const onSubmitHandler = useCallback(async () => {
      if (flags.FE_635_CAPTCHA) {
        setIsCaptchaLoading(true)
        captchaRef.current?.execute()
      } else {
        resetActionHandler({
          resetToken,
          resetEmail,
          newPassword,
        })
      }
    }, [resetToken, resetEmail, newPassword, flags.FE_635_CAPTCHA, captchaRef])

    const resetActionHandler = useCallback(
      async ({
        resetToken,
        resetEmail,
        newPassword,
        token,
      }: {
        resetToken?: string | null
        resetEmail?: string | null
        newPassword: string
        token?: string
      }) => {
        if (resetToken && resetEmail) {
          try {
            const res = await resetPassword({
              token: resetToken,
              email: resetEmail,
              newPassword: newPassword,
              captchaToken: token,
            })
            if (res) {
              success('reset.password_set')
              navigate('/login', { replace: true })
            }
          } finally {
            setShouldResetCaptcha(true)
          }
        } else {
          try {
            await sendResetEmail({
              email: newPassword,
              captchaToken: token,
            })
            success('reset.password_reset_sent')
            setIsResentEmailSent(true)
          } finally {
            setShouldResetCaptcha(true)
          }
        }
      },
      [],
    )

    const onEmailChange = useCallback((value: string) => {
      setInputValue(value)
    }, [])

    const onVerificationSuccessHandler = useCallback(
      async ({
        resetToken,
        resetEmail,
        newPassword,
        token,
      }: {
        resetToken?: string | null
        resetEmail?: string | null
        newPassword?: string
        token?: string
      }) => {
        setIsCaptchaLoading(false)
        resetActionHandler({
          resetToken,
          resetEmail,
          newPassword: newPassword!,
          token,
        })
      },
      [],
    )

    const onCaptchaResetHandler = useCallback(() => {
      setShouldResetCaptcha(false)
    }, [])

    return (
      <div css={formInner} className={className} {...dataAttr}>
        <div css={gradientText}>{t('reset.password_reset')}</div>
        {!resetToken && (
          <div className="info-text">{t('reset.reset_password_info')}</div>
        )}
        {resetToken ? (
          <>
            <Input
              size={INPUT_SIZE.SMALL}
              width={INPUT_WIDTH.FULL}
              label={t('reset.new_password')}
              onChange={onEmailChange}
              onEnter={onSubmitHandler}
              value={newPassword}
              type={'password'}
              validation={{
                [VALIDATION_RULE_TYPES.REQUIRED]: {
                  text: t('validation.error.required', {
                    name: t('common.password'),
                  }),
                },
                [VALIDATION_RULE_TYPES.MIN]: {
                  value: 6,
                  text: t('validation.min_char', {
                    name: t('common.password'),
                    value: 6,
                  }),
                },
              }}
              onValidation={setInputValid}
              errorAlignRight
              solidError
            />
          </>
        ) : (
          <Input
            size={INPUT_SIZE.SMALL}
            width={INPUT_WIDTH.FULL}
            label={t('common.email_address')}
            onChange={onEmailChange}
            onEnter={onSubmitHandler}
            value={newPassword}
            type={'email'}
            validation={{
              [VALIDATION_RULE_TYPES.REQUIRED]: {
                text: t('validation.error.required', {
                  name: t('common.email'),
                }),
              },
              [VALIDATION_RULE_TYPES.VALIDMAIL]: {
                text: t('validation.error.email'),
              },
            }}
            onValidation={setInputValid}
            errorAlignRight
            solidError
          />
        )}

        {flags.FE_635_CAPTCHA && (
          <Captcha
            key="hCaptcha-reset-password"
            ref={captchaRef}
            resetToken={resetToken}
            resetEmail={resetEmail}
            newPassword={newPassword}
            onCaptchaReset={onCaptchaResetHandler}
            shouldResetCaptcha={shouldResetCaptcha}
            onVerificationSuccess={onVerificationSuccessHandler}
          />
        )}

        <div css={actionsStyles}>
          {isResetEmailSent ? (
            inputValid ? (
              <LottieButton
                text={t('reset.resend')}
                isLoading={isLoading}
                onClick={onSubmitHandler}
              />
            ) : (
              <Button
                size={BUTTON_SIZE.SMALL}
                text={t('reset.resend')}
                theme={BUTTON_THEME.GRADIENT}
                onClick={onSubmitHandler}
                isLoading={isLoading}
                width={BUTTON_WIDTH.FULL}
                disabled={!inputValid}
              />
            )
          ) : isLoading || isCaptchaLoading ? (
            <LottieButton
              text={t(resetToken ? 'common.actions.reset' : 'reset.send_email')}
              isLoading={isLoading || isCaptchaLoading}
              onClick={onSubmitHandler}
            />
          ) : (
            <Button
              size={BUTTON_SIZE.SMALL}
              text={t(resetToken ? 'common.actions.reset' : 'reset.send_email')}
              theme={BUTTON_THEME.GRADIENT}
              onClick={onSubmitHandler}
              isLoading={isLoading || isCaptchaLoading}
              width={BUTTON_WIDTH.FULL}
              disabled={!inputValid}
            />
          )}
          <span>
            {t('reset.back_to')}
            <Button
              isLink
              size={BUTTON_SIZE.LARGE}
              text={t('login.login')}
              theme={BUTTON_THEME.PRIMARY}
              type={BUTTON_TYPE.GHOST}
              onClick={onLoginClickHandler}
            />
          </span>
        </div>
      </div>
    )
  },
)
