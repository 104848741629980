import i18n from 'src/i18n/config'

export const aiLoadingTexts = (lng: string) => [
  i18n.t('ai_loading.text1', { lng }),
  i18n.t('ai_loading.text2', { lng }),
  i18n.t('ai_loading.text3', { lng }),
  i18n.t('ai_loading.text4', { lng }),
  i18n.t('ai_loading.text5', { lng }),
  i18n.t('ai_loading.text6', { lng }),
  i18n.t('ai_loading.text7', { lng }),
  i18n.t('ai_loading.text8', { lng }),
  i18n.t('ai_loading.text9', { lng }),
  i18n.t('ai_loading.text10', { lng }),
  i18n.t('ai_loading.text11', { lng }),
]
