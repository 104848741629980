export enum ErrorDefinitions {
  VALIDATION_EMAIL_MALFORMED = 'VALIDATION_EMAIL_MALFORMED',
  VALIDATION_PASSWORD_LENGTH = 'VALIDATION_PASSWORD_LENGTH',
  AICREDITREASONDEFAULTVALUE_NOT_FOUND = 'AICREDITREASONDEFAULTVALUE_NOT_FOUND',
  API_ERROR = 'API_ERROR',
  DATE_ERROR = 'DATE_ERROR',
  INCORRECT_PROVIDER = 'INCORRECT_PROVIDER',
  KEY_NOT_FOUND = 'KEY_NOT_FOUND',
  NO_ORG_ACCESS = 'NO_ORG_ACCESS',
  NO_TEAM_ACCESS = 'NO_TEAM_ACCESS',
  PLAN_NOT_FOUND = 'PLAN_NOT_FOUND',
  ACTIVE_PAYMENT_IN_PROGRESS = 'ACTIVE_PAYMENT_IN_PROGRESS',
  PLAN_IS_NOT_AVAILABLE = 'PLAN_IS_NOT_AVAILABLE',
  PLAN_PRODUCT_NOT_FOUND = 'PLAN_PRODUCT_NOT_FOUND',
  PRICE_NOT_FOUND = 'PRICE_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_PLAN_NOT_FOUND = 'USER_PLAN_NOT_FOUND',
  USER_EXIST = 'USER_EXIST',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
  REFERRED_USER_EXIST = 'REFERRED_USER_EXIST',
  ALREADY_REFERRED = 'ALREADY_REFERRED',
  CAN_NOT_PURCHASE = 'CAN_NOT_PURCHASE',
  CAN_NOT_UPGRADE = 'CAN_NOT_UPGRADE',
  SUBSCRIPTION_NOT_FOUND = 'SUBSCRIPTION_NOT_FOUND',
  SUBSCRIPTION_ITEM_NOT_FOUND = 'SUBSCRIPTION_ITEM_NOT_FOUND',
  SUBSCRIPTION_CHANGE_NOT_FOUND = 'SUBSCRIPTION_CHANGE_NOT_FOUND',
  SUBSCRIPTION_IS_NOT_ACTIVE = 'SUBSCRIPTION_IS_NOT_ACTIVE',
  UNKNOWN_EVENT_TYPE = 'UNKNOWN_EVENT_TYPE',
  TEAM_NOT_FOUND = 'TEAM_NOT_FOUND',
  ORG_NOT_FOUND = 'ORG_NOT_FOUND',
  ACCESS_NOT_FOUND = 'ACCESS_NOT_FOUND',
  USER_ALREADY_EXISTS_IN_THIS_ORGANIZATION = 'USER_ALREADY_EXISTS_IN_THIS_ORGANIZATION',
  USERS_ALREADY_EXISTS_IN_THIS_TEAM = 'USERS_ALREADY_EXISTS_IN_THIS_TEAM',
  INVITATION_ALREADY_EXISTS = 'INVITATION_ALREADY_EXISTS',
  INVITATION_NOT_FOUND = 'INVITATION_NOT_FOUND',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  ORGANIZATION_SEATSIZE_DOWNGRADE = 'ORGANIZATION_SEATSIZE_DOWNGRADE',
  AI_CREDIT_REASON_NOT_DEFINED = 'AI_CREDIT_REASON_NOT_DEFINED',
  INSUFFICIENT_AI_CREDIT = 'INSUFFICIENT_AI_CREDIT',
  WEBHOOK_EXISTS = 'WEBHOOK_EXISTS',
  WEBHOOK_NOT_EXISTS = 'WEBHOOK_NOT_EXISTS',
  WEBHOOK_NO_CHANGE = 'WEBHOOK_NO_CHANGE',
  PASSWORD_RESET_REQUEST_EXPIRED_OR_NOT_FOUND = 'PASSWORD_RESET_REQUEST_EXPIRED_OR_NOT_FOUND',
  TEAM_IS_NOT_IN_THIS_ORGANIZATION = 'TEAM_IS_NOT_IN_THIS_ORGANIZATION',
  USERS_NOT_FOUND_IN_THIS_ORGANIZATION = 'USERS_NOT_FOUND_IN_THIS_ORGANIZATION',
  USER_NOT_FOUND_IN_THIS_TEAM = 'USER_NOT_FOUND_IN_THIS_TEAM',
  USER_NOT_FOUND_IN_THIS_ORGANIZATION = 'USER_NOT_FOUND_IN_THIS_ORGANIZATION',
  CANNOT_UPDATE_SELF_USER = 'CANNOT_UPDATE_SELF_USER',
  MISSING_QUESTION_TYPES = 'MISSING_QUESTION_TYPES',
  USER_ALREADY_CLASSIFIED = 'USER_ALREADY_CLASSIFIED',
  WRONG_VERIFICATION_CODE = 'WRONG_VERIFICATION_CODE',
  USER_ALREADY_VERIFIED = 'USER_ALREADY_VERIFIED',
  TARGET_USER_IS_OWNER = 'TARGET_USER_IS_OWNER',
  CANNOT_REMOVE_SELF_USER = 'CANNOT_REMOVE_SELF_USER',
  CANNOT_REMOVE_ADMIN_USER = 'CANNOT_REMOVE_ADMIN_USER',
  ORGANIZATION_SEATSIZE_REACHED = 'ORGANIZATION_SEATSIZE_REACHED',
  THEME_NOT_FOUND = 'THEME_NOT_FOUND',
  RAW_THEME_NOT_FOUND = 'RAW_THEME_NOT_FOUND',
  SLIDELAYOUT_EXIST = 'SLIDELAYOUT_EXIST',
  SLIDELAYOUT_NOT_FOUND = 'SLIDELAYOUT_NOT_FOUND',
  THEMECOLOR_NOT_FOUND = 'THEMECOLOR_NOT_FOUND',
  THEMEFONT_NOT_FOUND = 'THEMEFONT_NOT_FOUND',
  COMPONENT_NOT_FOUND = 'COMPONENT_NOT_FOUND',
  STRIPE_CUSTOMER_NOT_FOUND = 'STRIPE_CUSTOMER_NOT_FOUND',
  ASSET_NOT_FOUND = 'ASSET_NOT_FOUND',
  FOLDER_NOT_FOUND = 'FOLDER_NOT_FOUND',
  DECK_NOT_FOUND = 'DECK_NOT_FOUND',
  DECK_ALREADY_SHARED = 'DECK_ALREADY_SHARED',
  DECKSHARE_NOT_FOUND = 'DECKSHARE_NOT_FOUND',
  CUSTOMDOMAIN_NOT_FOUND = 'CUSTOMDOMAIN_NOT_FOUND',
  SHORTCODE_ALREADY_EXISTS = 'SHORTCODE_ALREADY_EXISTS',
  CUSTOMDOMAIN_ALREADY_EXISTS = 'CUSTOMDOMAIN_ALREADY_EXISTS',
  CUSTOMDOMAINSLUG_ALREADY_EXISTS = 'CUSTOMDOMAINSLUG_ALREADY_EXISTS',
  SLIDEDATA_NOT_FOUND = 'SLIDEDATA_NOT_FOUND',
  DECKDATA_NOT_FOUND = 'DECKDATA_NOT_FOUND',
  OPENAI_MAXRETRY_REACHED = 'OPENAI_MAXRETRY_REACHED',
  THEMECATEGORY_NOT_FOUND = 'THEMECATEGORY_NOT_FOUND',
  MINROLE_ERROR = 'MINROLE_ERROR',
  SLIDE_NOT_FOUND = 'SLIDE_NOT_FOUND',
  INVALID_OFFSET = 'INVALID_OFFSET',
  FORM_QUESTION_NOT_FOUND = 'FORM_QUESTION_NOT_FOUND',
  UNKNOWN_FORMQUESTION_TYPE = 'UNKNOWN_FORMQUESTION_TYPE',
  MODERATION_ERROR = 'MODERATION_ERROR',
  DECKPROMPT_NOT_FOUND = 'DECKPROMPT_NOT_FOUND',
  DECKSTATE_IS_NOT_FINAL = 'DECKSTATE_IS_NOT_FINAL',
  THEMECOLOR_THEMEFONT_NOT_IN_SAME_THEMECATEGORY = 'THEMECOLOR_THEMEFONT_NOT_IN_SAME_THEMECATEGORY',
  CONTENT_TOO_LONG = 'CONTENT_TOO_LONG',
  DECK_EXPORT_IN_PROGRESS = 'DECK_EXPORT_IN_PROGRESS',
  CANNOT_UPDATE_SELF_PERMISSION = 'CANNOT_UPDATE_SELF_PERMISSION',
  CANNOT_UPDATE_OWNERS_PERMISSION = 'CANNOT_UPDATE_OWNERS_PERMISSION',
  USER_DECK_NOT_FOUND = 'USER_DECK_NOT_FOUND',
  PASSWORD_RESET_REQUIRED = 'PASSWORD_RESET_REQUIRED',
  PERMISSION_ERROR = 'PERMISSION_ERROR',
  AI_IMAGE_GENERATION_FAILED = 'AI_IMAGE_GENERATION_FAILED',
  NOT_AI_DECK = 'NOT_AI_DECK',
  MULTIPLE_FORM_IN_SLIDE_NOT_ALLOWED = 'MULTIPLE_FORM_IN_SLIDE_NOT_ALLOWED',
  LINECHART_LAYOUT_NOT_ENABLED = 'LINECHART_LAYOUT_NOT_ENABLED',
  CUSTOMDOMAIN_REQUIRES_HTTPS = 'CUSTOMDOMAIN_REQUIRES_HTTPS',
  USER_INACTIVE = 'USER_INACTIVE',
  NO_ANSWER_ON_REQUIRED_QUESTION = 'NO_ANSWER_ON_REQUIRED_QUESTION',
  CANNOT_LOGIN_AS_ADMIN = 'CANNOT_LOGIN_AS_ADMIN',
  CANNOT_DELETE_PIVOT_DECK = 'CANNOT_DELETE_PIVOT_DECK',
  CAPTCHA_NOT_CORRECT = 'CAPTCHA_NOT_CORRECT',
}
