import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setUser,
  setAICredits,
  setAICreditsSummary,
  setPortalUrl,
  setOrganizations,
  setWorkspaceId,
  setIsOwner,
} from 'src/store'
import { API_CONFIG } from 'src/config'
import {
  RequestResponse,
  RequestServices,
  LanguageServices,
} from 'src/services'
import {
  getAICreditSpendSummarizedResponse,
  getAICreditsTotalResponse,
  getCustomerStripePortalResponse,
  getUserDetailsResponse,
  getUserOrganizationsResponse,
  getUserInvitesResponse,
  defaultResponseSchema,
} from 'src/types/api/responseObjects'
import {
  putUpdateUserBody,
  postSaveUserClassifiesBody,
} from 'src/types/api/requestObjects'
import { useNotification, useLanguage } from 'src/hooks'
import {
  OrganizationInviteStates,
  DeckInviteStates,
  PlanNames,
  PlanPeriods,
} from 'src/types/api/enums'
import { usePostHog } from 'posthog-js/react'
import { getEnumKeyByValue } from 'src/helpers'
import Gleap from 'gleap'

interface IUseUserApiReturn {
  getUser: () => Promise<getUserDetailsResponse['data']>
  updateUser: (data: Partial<putUpdateUserBody>) => Promise<void>
  getAICredits: () => Promise<void>
  getAICreditsSummary: (range: {
    startDate: Date
    endDate: Date
  }) => Promise<void>
  getPortalUrl: () => Promise<string>
  sendVerificationEmail: () => Promise<void>
  validateVerificationCode: (code: string) => Promise<void>
  getOrganizations: () => Promise<
    getUserOrganizationsResponse['data']['organizationUsers']
  >
  postSaveUserClassifies: (
    classifies: postSaveUserClassifiesBody['classifies'],
  ) => Promise<void>
  postReferEmail: (email: string) => Promise<boolean>
  acceptAllInvites: () => void
  isLoading: boolean
}

export const useUserApi = (): IUseUserApiReturn => {
  const dispatch = useDispatch()
  const { success } = useNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const posthog = usePostHog()
  const { lang, changeLanguage } = useLanguage()

  const getUser = async (): Promise<getUserDetailsResponse['data']> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getUserDetailsResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.USER,
        })
      dispatch(setUser(res.data.data))

      const user = res.data.data.user!
      const userMeta = res.data.data.meta

      const userLanguage = res.data.data.user?.language
      if (userLanguage) {
        const langValue = LanguageServices.enumToLanguage(userLanguage)
        if (langValue !== lang) {
          changeLanguage(langValue)
        }
      }

      const userStatus = userMeta.subscription?.isChurn
        ? 'Canceled'
        : user.activeUserPlan
        ? `${getEnumKeyByValue(
            PlanNames,
            user.activeUserPlan.plan.name,
          )} ${getEnumKeyByValue(PlanPeriods, user.activeUserPlan.plan.period)}`
        : 'Free'

      try {
        if (posthog && posthog.__loaded) {
          posthog.identify(res.data.data.user?.email, {
            email: res.data.data.user?.email,
            status: userStatus,
            isChurn: userMeta.subscription?.isChurn,
          })
        }
      } catch {
        console.error('Posthog identify useUserApi error: ')
      }

      try {
        Gleap.identify(user.id.toString(), {
          name: user.fullName,
          email: user.email,
          plan: userStatus,
        })
      } catch {
        console.error('Gleap identify useUserApi error: ')
      }

      return res.data.data
    } finally {
      setIsLoading(false)
    }
  }

  const updateUser = async (data: Partial<putUpdateUserBody>) => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: API_CONFIG.USER,
        data,
      })

      getUser()
      if (data.fullName) {
        success('profile.update.name_change_success')
      }
      if (data.picture) {
        success('profile.update.image_upload_success')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const postSaveUserClassifies = async (
    classifies: postSaveUserClassifiesBody['classifies'],
  ) => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: `${API_CONFIG.USER}/classify`,
        data: { classifies },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getAICredits = async () => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getAICreditsTotalResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.USER_AICREDITS_TOTAL,
        })
      dispatch(setAICredits(res.data.data.totalCredits))
    } finally {
      setIsLoading(false)
    }
  }

  const getAICreditsSummary = async ({
    startDate,
    endDate,
  }: IUseUserApiReturn['getAICreditsSummary']['arguments']): Promise<void> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getAICreditSpendSummarizedResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.USER_AICREDITS_HISTORY_SPEND_SUMMARY,
          params: {
            startDate,
            endDate,
          },
        })
      dispatch(setAICreditsSummary(res.data.data))
    } finally {
      setIsLoading(false)
    }
  }

  const getPortalUrl = async () => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getCustomerStripePortalResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.USER_PORTAL_URL,
        })
      dispatch(setPortalUrl(res.data.data.portalUrl))
      return res.data.data.portalUrl
    } finally {
      setIsLoading(false)
    }
  }

  const sendVerificationEmail = async () => {
    setIsLoading(true)
    try {
      await RequestServices.request({
        method: 'POST',
        url: API_CONFIG.USER_VERIFICATION_EMAIL,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const validateVerificationCode = async (verificationCode: string) => {
    setIsLoading(true)
    try {
      await RequestServices.request({
        method: 'POST',
        url: API_CONFIG.USER_VERIFICATION,
        data: {
          verificationCode,
        },
      })
      getUser()
      success('profile.verification_success')
    } finally {
      setIsLoading(false)
    }
  }

  const getOrganizations = async () => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getUserOrganizationsResponse, any> =
        await RequestServices.request({
          method: 'GET',
          url: API_CONFIG.USER_ORGANIZATIONS,
        })
      dispatch(setOrganizations(res.data.data.organizationUsers))
      return res.data.data.organizationUsers
    } finally {
      setIsLoading(false)
    }
  }

  const acceptAllInvites = async () => {
    // TODO: This function will be handled separetely for accepting organization and deck invites
    try {
      const res: RequestResponse<getUserInvitesResponse, any> =
        await RequestServices.request({
          method: 'GET',
          url: API_CONFIG.USER_INVITES,
        })

      const organizationInvites = res.data.data.organizationInvites
      if (organizationInvites.length) {
        for (const invite of organizationInvites) {
          await RequestServices.request({
            method: 'POST',
            url: API_CONFIG.ORGANIZATION_INVITE_RESPOND({
              orgId: invite.organization.id.toString(),
              inviteId: invite.id.toString(),
            }),
            data: {
              newState: OrganizationInviteStates.ACCEPTED,
            },
          })
          dispatch(setWorkspaceId(invite.organization.id))
          dispatch(setIsOwner(false))
        }
        await getOrganizations()
      }

      const deckInvites = res.data.data.deckInvites
      if (deckInvites.length)
        for (const invite of deckInvites) {
          await RequestServices.request({
            method: 'POST',
            url: API_CONFIG.DECK_INVITE_RESPOND({
              deckId: invite.deck.id.toString(),
              inviteId: invite.id.toString(),
            }),
            data: {
              newState: DeckInviteStates.ACCEPTED,
            },
          })
        }
    } catch (error) {
      console.error(error)
    } finally {
      console.info('all invites for decks and organizations are accepted.')
    }
  }

  const postReferEmail = async (email: string) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<defaultResponseSchema, any> =
        await RequestServices.request({
          method: 'POST',
          url: API_CONFIG.USER_REFER,
          data: {
            referredEmail: email,
          },
        })

      return res.data.code === 200
    } finally {
      setIsLoading(false)
    }
  }

  return {
    getUser,
    updateUser,
    getAICredits,
    getAICreditsSummary,
    getPortalUrl,
    sendVerificationEmail,
    validateVerificationCode,
    getOrganizations,
    acceptAllInvites,
    postReferEmail,
    postSaveUserClassifies,
    isLoading,
  }
}
