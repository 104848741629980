import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'

import {
  RootState,
  setSelectedComponents,
  toggleSelectedComponents,
} from 'src/store'

import { useClickOutside } from 'src/hooks'
import { CANVAS_ITEM_TYPE, CANVAS_TYPE } from 'src/components/canvas'
import { IDragController } from './types'
import { dragControllerStyles } from './styles'
import { ContextMenu, HitPoints, IContextMenu } from './components'
import { Element } from '../element'
import {
  ComponentListDataSchema,
  ComponentTextDataSchema,
} from 'src/types/api/requestObjects'
import { UNSELECT_EXCEPTION } from 'src/data'

export const DragController: React.FC<IDragController> = React.memo(
  ({
    canvasType = CANVAS_TYPE.DND,
    data,
    hasBorder,
    onHover,
    scale = 1,
    zIndexList,
    className,
    dataAttr,
  }) => {
    const dispatch = useDispatch()
    const [canDraggable, setDraggable] = useState(true)

    const { selectedComponents, isComponentFixing } = useSelector(
      ({ canvas }: RootState) => ({
        selectedComponents: canvas.selectedComponents,
        isComponentFixing: canvas.componentFixInProgress,
      }),
    )

    const [{ isDragging }, elementDrag, preview] = useDrag(() => {
      return {
        type: CANVAS_ITEM_TYPE.CANVAS_ITEM,
        canDrag: canDraggable,
        item: [data],
        end: () => {
          document.dispatchEvent(
            new CustomEvent('element-scale', { detail: false }),
          )
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }
    }, [data, canDraggable])

    const isFixed = useMemo(
      () =>
        canvasType === CANVAS_TYPE.DND ||
        canvasType === CANVAS_TYPE.STATIC ||
        canvasType === CANVAS_TYPE.PREVIEW,
      [canvasType],
    )

    const isSelected = useMemo(() => {
      const draggable = canvasType === CANVAS_TYPE.DND
      const selectedByID =
        data?.id && selectedComponents.find(({ id }) => id === data?.id)
      const selectedByTempID =
        data?.tempId &&
        selectedComponents.find(({ tempId }) => tempId === data?.tempId)
      return !!(draggable && (selectedByID || selectedByTempID))
    }, [data, selectedComponents, isDragging, canvasType])

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true })
    }, [])

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        if (e.shiftKey) {
          dispatch(
            toggleSelectedComponents({
              id: data.id,
              tempId: data.tempId || undefined,
              type: data.type,
            }),
          )
        } else {
          dispatch(
            setSelectedComponents([
              {
                id: data.id,
                tempId: data.tempId || undefined,
                type: data.type,
              },
            ]),
          )
        }
      },
      [data],
    )

    useEffect(() => {
      if (isDragging) {
        dispatch(
          setSelectedComponents([
            { id: data.id, tempId: data.tempId || undefined, type: data.type },
          ]),
        )
      }
    }, [isDragging])

    const [currentSize, setCurrentSize] = useState({
      width: data.positions.width,
      height: data.positions.height,
      x: data.positions.x,
      y: data.positions.y,
    })

    const [temporaryHeight, setTemporaryHeight] = useState(currentSize.height)

    const setCurrentHeight = useCallback(
      (newHeight: number) => {
        setTemporaryHeight(newHeight)
      },
      [currentSize],
    )

    const [cornerSize, setCornerSize] = useState<{
      fontSize?: string
      fontBodySize?: string | null
    }>({
      fontSize:
        (data.data as ComponentTextDataSchema)?.style?.font?.size || undefined,
      fontBodySize:
        (data.data as ComponentListDataSchema)?.style?.fontBody?.size ||
        undefined,
    })

    const [cornerScaling, setCornerScaling] = useState(false)
    const [scaling, setScaling] = useState(false)

    useEffect(() => {
      setCurrentSize({
        width: data.positions.width,
        height: data.positions.height,
        x: data.positions.x,
        y: data.positions.y,
      })

      const fontSize =
        (data.data as ComponentTextDataSchema)?.style?.font?.size || undefined
      const fontBodySize =
        (data.data as ComponentListDataSchema)?.style?.fontBody?.size ||
        undefined
      setCornerSize({
        fontSize,
        fontBodySize,
      })
    }, [data])

    const dynamicStyles = useMemo(() => {
      return {
        opacity: isDragging ? 0 : 1,
        width: `${currentSize.width}px`,
        height: `${
          isComponentFixing || scaling ? currentSize.height : temporaryHeight
        }px`,
        top: isFixed ? `${currentSize.y}px` : undefined,
        left: isFixed ? `${currentSize.x}px` : undefined,
      }
    }, [
      isDragging,
      isFixed,
      currentSize,
      isComponentFixing,
      scaling,
      temporaryHeight,
    ])

    const dragRef = useMemo(
      () => (canvasType === CANVAS_TYPE.DND ? elementDrag : null),
      [canvasType],
    )

    const contextMenuRef = useRef<HTMLDivElement>(null)
    const [contextMenuPos, setContextMenuPos] = useState<IContextMenu['pos']>()
    useClickOutside(contextMenuRef, () => setContextMenuPos(undefined))
    const onContextMenu = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setContextMenuPos({ x: e.clientX, y: e.clientY })
        handleClick(e)
      },
      [isSelected],
    )
    useEffect(() => {
      if (!isSelected) {
        setContextMenuPos(undefined)
      }
    }, [isSelected])

    const handleDoubleClick = useCallback(() => {
      setTimeout(() => {}, 200)

      document.dispatchEvent(new CustomEvent('element-double-click'))
    }, [])

    const zIndex = useMemo(() => {
      if (data.positions.zIndex === 2) {
        return 2
      } else {
        return zIndexList.findIndex((i) => i === data.positions.zIndex) + 5
      }
    }, [data.positions.zIndex, zIndexList])

    const handleDraggableChange = useCallback((state: boolean) => {
      setDraggable(state)
    }, [])

    return (
      <div
        ref={dragRef}
        css={dragControllerStyles({
          isSelected: isSelected || hasBorder,
          canvasType,
        })}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onContextMenu={onContextMenu}
        style={{
          ...dynamicStyles,
          zIndex,
        }}
        className={className}
        onMouseOver={() => onHover?.(true)}
        onMouseLeave={() => onHover?.(false)}
        {...{ [UNSELECT_EXCEPTION]: true }}
        {...dataAttr}
      >
        {isSelected && (
          <HitPoints
            data={data}
            scale={scale}
            onScale={setCurrentSize}
            onCornerScale={setCornerSize}
            isCornerScaling={setCornerScaling}
            isScaling={setScaling}
            // allowEdges={data.type !== ComponentTypes.BUTTON}
          />
        )}
        <Element
          isSelected={isSelected}
          data={data}
          overrides={{
            fontSize: cornerSize.fontSize,
            fontBodySize: cornerSize.fontBodySize,
          }}
          isScaling={cornerScaling}
          scale={scale}
          canvasType={canvasType}
          canDrag={handleDraggableChange}
          onHeightChange={setCurrentHeight}
        />
        <ContextMenu
          onClose={() => {
            setContextMenuPos(undefined)
          }}
          data={data}
          ref={contextMenuRef}
          pos={contextMenuPos}
        />
      </div>
    )
  },
)

DragController.displayName = 'DragController'
