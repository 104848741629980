import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AI_FLOW_STEP,
  RootState,
  setAiCreatedDeckId,
  setAiFlowStep,
  setTheme,
} from 'src/store'
import { useAiApi, useDecksApi, useLanguage } from 'src/hooks'
import { IAiTemplates } from './types'
import { buttonStyles, viewStyles } from '../ai-prompt/styles'

import { BUTTON_THEME, BUTTON_TYPE, Button, Icon, icons } from 'src/lib'
import { Steps } from 'src/lib/steps'
import { imageStyles, subTitleStyles, themeAreaStyles } from './styles'
import { pdfNameStyles } from '../styles'
import { ACTION_CLICKS } from 'src/plugins/google/consts'
import { AiLoading } from '../ai-loading'
import { DeckStates } from 'src/types/api/enums'
import { AiError } from '../ai-error'
import { UpgradeModalManager } from 'src/components/upgrade-modals/UpgradeModalManager'
import { ErrorDefinitions } from 'src/types/api/ErrorDefinitions'
import { APP_CONFIG } from 'src/config'

export const AiTemplates: React.FC<IAiTemplates> = React.memo(
  ({ className, dataAttr, onClose }) => {
    const { t } = useLanguage()

    const dispatch = useDispatch()
    const { newAiDeck, getDecks } = useDecksApi()
    const { updateDeckPrompt } = useAiApi()

    const {
      promptId,
      pdfName,
      pdfImport,
      templateData,
      workspaceId,
      currentPlan,
      credits,
      orgCredits,
      decks,
      anyPlan,
      aiFlowStep,
    } = useSelector(({ aiFlow, workspace, user, org, decks }: RootState) => ({
      promptId: aiFlow.selectedPromptId,
      pdfName: aiFlow.pdfFileName,
      pdfImport: aiFlow.pdfImport,
      workspaceId: workspace.id,
      templateData: aiFlow.templates?.data.themes,
      currentPlan: user.data?.activeUserPlan?.plan.name,
      credits: user.aicredits.total,
      orgCredits: org.aicredits.total,
      decks: decks.list.data,
      anyPlan: user.data?.activeUserPlan,
      aiFlowStep: aiFlow.currentStep,
    }))

    useEffect(() => {
      const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          if (selectedImageId != 0) {
            generateButton()
          }
        }
      }
      document.addEventListener('keydown', keyDownHandler)
      return () => {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }, [])

    const [loadingState, setLoadingState] = useState(false)
    const [errorState, setErrorState] = useState(false)

    const [selectedImageId, setSelectedImageId] = useState(0)
    const [deckId, setDeckId] = useState(0)

    const imageChange = (value: number) => {
      setSelectedImageId(value)
      sessionStorage.setItem('selectedImageId', value.toString())
      dispatch(setTheme(value.toString()))
    }

    useEffect(() => {
      const deck = decks?.find((item) => item.deck.id === deckId)
      const updateDeckFinalStatus = async () => {
        dispatch(setAiFlowStep(AI_FLOW_STEP.COMPLETED))
        await new Promise((resolve) =>
          setTimeout(resolve, APP_CONFIG.aiLoadingCompleteTimeout),
        )
        onClose && onClose()
      }
      if (deck) {
        const deckState = deck.deck.state
        if (deckState === DeckStates.FINAL) {
          updateDeckFinalStatus()
        } else if (deckState === DeckStates.ERRORED) {
          setErrorState(true)
          dispatch(setAiFlowStep(AI_FLOW_STEP.ERRORED))
        } else {
          setLoadingState(true)
          dispatch(setAiFlowStep(AI_FLOW_STEP.LOADING))
        }
      }
    }, [decks, deckId])

    const generateButton = useCallback(async () => {
      dispatch(setTheme(selectedImageId.toString()))
      setLoadingState(true)

      await updateDeckPrompt({
        deckPromptId: promptId,
        themeId: selectedImageId,
      })

      const createdDeck = await newAiDeck({
        themeId: selectedImageId,
        deckPromptId: promptId,
        organizationId: workspaceId,
      })
      if (createdDeck === ErrorDefinitions.INSUFFICIENT_AI_CREDIT) {
        setLoadingState(false)
        setIsUpgradeModalOpen(true)
        return
      }
      if (!createdDeck) {
        setLoadingState(false)
        return
      }
      if (createdDeck && typeof createdDeck === 'number') {
        setDeckId(createdDeck)
        dispatch(setAiCreatedDeckId(createdDeck))
        dispatch(setAiFlowStep(AI_FLOW_STEP.LOADING))
      }
      await getDecks()
    }, [
      workspaceId,
      loadingState,
      orgCredits,
      currentPlan,
      credits,
      promptId,
      selectedImageId,
      anyPlan,
    ])

    const generateAfterPayment = useCallback(async () => {
      setIsUpgradeModalOpen(false)
      const themeId = Number(sessionStorage.getItem('selectedImageId'))
      dispatch(setAiFlowStep(AI_FLOW_STEP.LOADING))
      setLoadingState(true)
      dispatch(setTheme(selectedImageId.toString()))

      await updateDeckPrompt({
        deckPromptId: promptId,
        themeId: selectedImageId,
      })

      const createdDeck = await newAiDeck({
        themeId: selectedImageId === 0 ? themeId : selectedImageId,
        deckPromptId: promptId,
        organizationId: workspaceId,
      })
      if (createdDeck && typeof createdDeck === 'number') {
        setDeckId(createdDeck)
      }
      await getDecks()
      setLoadingState(false)
    }, [selectedImageId, promptId, workspaceId])

    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)

    const backButton = () => {
      if (pdfImport) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.AIM))
      } else {
        dispatch(setAiFlowStep(AI_FLOW_STEP.OUTLINE))
      }
    }

    const templates = useMemo(() => {
      return templateData?.map((item, index) => (
        <div onClick={() => imageChange(item.id)} key={index}>
          <img
            src={item.themeCategory.thumbnailUrl}
            css={imageStyles(selectedImageId == item.id)}
          />
        </div>
      ))
    }, [selectedImageId, templateData])

    return (
      <div className={className} {...dataAttr}>
        {errorState ? (
          <AiError />
        ) : loadingState || aiFlowStep === AI_FLOW_STEP.COMPLETED ? (
          <AiLoading onClose={onClose} />
        ) : (
          <>
            <div css={viewStyles(false)}>
              <Steps steps={pdfName ? 3 : 5} current={pdfName ? 3 : 5} />
              <>
                <div css={subTitleStyles}>
                  {t('create_with_ai.theme_subtitle')}
                </div>
                {pdfImport && (
                  <div css={pdfNameStyles}>
                    <Icon icon={icons.pdf}></Icon>
                    {pdfName}
                  </div>
                )}
                <div css={themeAreaStyles}>{templates}</div>
              </>
            </div>
            <div css={buttonStyles}>
              <Button
                type={BUTTON_TYPE.GHOST}
                text={t('common.actions.back')}
                icon={icons.chevron_left}
                isLink
                onClick={backButton}
              />
              <Button
                text={t('create_with_ai.generate_presentation')}
                theme={BUTTON_THEME.GRADIENT}
                onClick={generateButton}
                disabled={selectedImageId == 0}
                isLoading={loadingState}
              />
            </div>

            <UpgradeModalManager
              isOpen={isUpgradeModalOpen}
              callback={generateAfterPayment}
              refillMode={true}
              context={ACTION_CLICKS.AI_CLICK}
              onClose={() => setIsUpgradeModalOpen(false)}
            />
          </>
        )}
      </div>
    )
  },
)

AiTemplates.displayName = 'AiTemplates'
