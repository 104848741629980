import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { ComponentTypes } from 'src/types/api/enums'
import { TextProps } from './components/text-props'
import { EditSubmenuTypes, IEditPropSection, IEditProperties } from './types'
import { editPropertiesStyles } from './styles'
import {
  ComponentMediaDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import {
  AlignmentProps,
  ChartProps,
  DeviceProps,
  EditTable,
  ShapeProps,
  TableProps,
  VideoProps,
  MediaButtons,
  MediaProps,
  FormProps,
  FormSubProps,
  QuoteProps,
  ButtonProps,
  ListPropsNew,
  MediaOpacity,
} from './components'
import { MediaTypes } from 'src/types/api/enums'
import { PropSubMenu } from './props'
import { useFlags, useLanguage } from 'src/hooks'
import { WebsiteProps } from './components/website-props'
import { MediaCorners } from './components/media-props/MediaCorners'

export const EditProperties: React.FC<IEditProperties> = React.memo(
  ({ className, dataAttr }) => {
    const flags = useFlags()
    const { t } = useLanguage()
    const [submenuType, setSubmenuType] = useState<{ type: EditSubmenuTypes }>()
    const { slides, activeSlide, selectedComponents } = useSelector(
      ({ edit, canvas }: RootState) => ({
        activeSlide: edit.activeSlideDataID,
        slides: edit.activeSlideData.data,
        selectedComponents: canvas.selectedComponents,
      }),
    )

    const activeSlideElements = useMemo(
      () =>
        slides
          ?.find(({ id }) => id === activeSlide)
          ?.slideDataComponents.map(({ component }) => component),
      [slides, activeSlide],
    )

    const activeElements: UpdateComponentSchema[] = useMemo(
      () =>
        activeSlideElements?.filter((element) =>
          selectedComponents.find(
            (selectedElement) =>
              (element.id && element.id === selectedElement.id) ||
              (element.tempId && element.tempId == selectedElement.tempId),
          ),
        ) || [],
      [activeSlideElements, selectedComponents],
    )

    const selectedTypes = useMemo(
      () =>
        activeElements.reduce(
          (a: ComponentTypes[], c) =>
            c.type && !a.includes(c.type) ? [...a, c.type] : a,
          [],
        ),
      [activeElements],
    )

    const isType = useCallback(
      (type: ComponentTypes) => selectedTypes.includes(type),
      [selectedTypes],
    )

    const isSingleType = useMemo(
      () => selectedTypes.length < 2,
      [selectedTypes],
    )

    const isSingleSelection = useMemo(
      () => selectedComponents.length === 1,
      [selectedComponents],
    )

    const selectedMediaTypes = useMemo(
      () =>
        activeElements?.reduce(
          (a: MediaTypes | undefined | null, c: UpdateComponentSchema) => {
            const cType = (c.data as ComponentMediaDataSchema).mediaType
            if (a === undefined && cType) {
              return cType
            }

            return cType !== a ? null : a
          },
          undefined,
        ),
      [activeElements],
    )

    const propertyRules = useMemo<
      {
        Component: React.FC<IEditPropSection>
        singleType?: boolean
        singleSelection?: boolean
        type?: ComponentTypes
        special?: boolean
      }[]
    >(
      () => [
        {
          Component: EditTable,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.TABLE,
        },
        {
          Component: MediaButtons,
          singleType: true,
          type: ComponentTypes.MEDIA,
          special:
            selectedMediaTypes !== MediaTypes.VIDEO &&
            selectedMediaTypes !== MediaTypes.WEBSITE &&
            selectedMediaTypes !== MediaTypes.SHAPE,
        },
        {
          Component: AlignmentProps,
        },
        {
          Component: TextProps,
          singleType: true,
          type: ComponentTypes.TEXT,
        },
        {
          Component: MediaProps,
          singleType: true,
          type: ComponentTypes.MEDIA,
          special:
            selectedMediaTypes === MediaTypes.IMAGE ||
            selectedMediaTypes === MediaTypes.GIF ||
            selectedMediaTypes === MediaTypes.ICON,
        },
        {
          Component: ShapeProps,
          singleType: true,
          type: ComponentTypes.MEDIA,
          special: selectedMediaTypes === MediaTypes.SHAPE,
        },
        {
          Component: MediaCorners,
          type: ComponentTypes.MEDIA,
          special:
            selectedMediaTypes !== MediaTypes.VIDEO &&
            selectedMediaTypes !== MediaTypes.WEBSITE &&
            selectedMediaTypes !== MediaTypes.ICON &&
            flags.FE_421_IMAGE_CORNER_RADIUS,
        },
        {
          Component: MediaOpacity,
          type: ComponentTypes.MEDIA,
          special:
            selectedMediaTypes !== MediaTypes.VIDEO &&
            selectedMediaTypes !== MediaTypes.WEBSITE,
        },
        {
          Component: ChartProps,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.CHART,
        },
        {
          Component: TableProps,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.TABLE,
        },
        {
          Component: VideoProps,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.MEDIA,
          special: selectedMediaTypes === MediaTypes.VIDEO,
        },
        {
          Component: WebsiteProps,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.MEDIA,
          special: selectedMediaTypes === MediaTypes.WEBSITE,
        },
        {
          Component: DeviceProps,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.DEVICE,
        },
        {
          Component: ListPropsNew,
          singleType: true,
          singleSelection: false,
          type: ComponentTypes.LIST,
        },
        {
          Component: FormProps,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.FORM,
        },
        {
          Component: QuoteProps,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.QUOTE,
        },
        {
          Component: ButtonProps,
          singleType: true,
          singleSelection: true,
          type: ComponentTypes.BUTTON,
        },
      ],
      [selectedMediaTypes],
    )

    const renderPropertySections = useMemo(
      () =>
        propertyRules.map(
          (
            { Component, singleType, singleSelection, type, special },
            index,
          ) => {
            const singleTypeCond = singleType ? isSingleType : true
            const singleSelectCond = singleSelection ? isSingleSelection : true
            const typeCond = type ? isType(type) : true
            const specialCond = special !== undefined ? special : true

            if (singleTypeCond && singleSelectCond && typeCond && specialCond) {
              return (
                <Component
                  key={index}
                  components={activeElements}
                  showSubMenu={setSubmenuType}
                />
              )
            }
          },
        ),
      [activeElements, propertyRules],
    )

    return submenuType?.type === EditSubmenuTypes.FORM_PROPS ? (
      <PropSubMenu
        onBack={() => setSubmenuType(undefined)}
        title={t('edit.properties.form.submenu.title')}
      >
        <FormSubProps components={activeElements} />
      </PropSubMenu>
    ) : (
      <div css={editPropertiesStyles} className={className} {...dataAttr}>
        {renderPropertySections}
      </div>
    )
  },
)

EditProperties.displayName = 'EditProperties'
