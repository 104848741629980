import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IDashboardCard } from './types'
import { dashboardCardStyles, pivotIconStyles } from './styles'
import {
  Button,
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  Card,
  Dialog,
  Icon,
  icons,
  Modal,
  Tooltip,
} from 'src/lib'
import { ShareView } from 'src/components/share-view'
import { colors, theme } from 'src/theme'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setDeleteDeck, setUpdateTime } from 'src/store'
import {
  useDecksApi,
  useNotification,
  useLanguage,
  VALIDATION_RULE_TYPES,
} from 'src/hooks'
import { Analytics } from 'src/components/analytics'
import { DeckPermissions, DeckStates, ViewModes } from 'src/types/api/enums'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { PlanNames } from 'src/types/api/enums'
import { ACTION_CLICKS } from 'src/plugins/google/consts'
import { FormResponses } from 'src/components/form-responses'
import { DECKTOPUS_WORKSPACE_ID } from 'src/components/workspace-selector/consts'
import { APP_CONFIG } from 'src/config'

export const DashboardCard: React.FC<IDashboardCard> = React.memo(
  ({
    className,
    dataAttr,
    id,
    title,
    subtitle,
    subtitleInfo,
    footerIcon,
    footerText,
    footerTextInfo,
    avatar,
    folderDeck,
    thumbnailUrl,
    deckListIndex,
    viewMode,
    state,
    data,
    permission,
  }) => {
    const { t } = useLanguage()

    const { folderList, workspaceId, userPlan, userId, orgUsers } = useSelector(
      ({ folders, workspace, user }: RootState) => ({
        userId: user.data?.id,
        folderList: folders.list,
        workspaceId: workspace.id,
        userPlan: user.data?.activeUserPlan?.plan.name,
        orgUsers: user.organizationUsers,
      }),
    )

    const dispatch = useDispatch()
    const timeRef = useRef<NodeJS.Timeout>()
    const {
      moveDeck,
      updateDeck,
      deleteDeck,
      duplicateDeck,
      getSingleDeck,
      deleteDeckCollabrator,
      publishPivotDeck,
    } = useDecksApi()
    const { success, error } = useNotification()

    const [isDuplicating, setIsDuplicating] = useState(false)

    const [newDeckName, setNewDeckName] = useState(title)
    const [shareModal, setShareModal] = useState(false)
    const [renameModal, setRenameModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [analyticsModal, setAnalyticsModal] = useState(false)
    const [formResponsesModal, setFormResponsesModal] = useState(false)
    const [selectedOption, setSelectedOption] = useState<
      'response' | 'analytics' | null
    >(null)

    useEffect(() => {
      setNewDeckName(title)
    }, [renameModal])

    const checkDeckState = useCallback(async () => {
      const deckState = await getSingleDeck({ deckId: id })
      if (deckState === DeckStates.FINAL) {
        clearInterval(timeRef.current)
        success('common.informative.your_deck_is_ready')
      } else if (deckState === DeckStates.ERRORED) {
        clearInterval(timeRef.current)
        error('common.informative.ai_high_demand_text')
      }
    }, [id, timeRef])

    const handleDeckState = useCallback(async () => {
      if (
        state === DeckStates.FINAL ||
        state === DeckStates.ERRORED ||
        state === DeckStates.READY_FOR_TRANSFER
      ) {
        return
      } else if (
        state === DeckStates.IN_QUEUE ||
        state === DeckStates.PROCESSING
      ) {
        timeRef.current = setInterval(checkDeckState, APP_CONFIG.aiDeckInterval)
        await checkDeckState()
      }
    }, [state, timeRef])

    useEffect(() => {
      handleDeckState()
      return () => clearInterval(timeRef.current)
    }, [state])

    const onRenameHandler = useCallback(async () => {
      setRenameModal(false)

      const status = await updateDeck(
        { deckId: id },
        {
          name: newDeckName,
          organizationId: workspaceId,
        },
      )
      if (status) {
        dispatch(setUpdateTime(deckListIndex))
        success('dashboard.card.deck_renamed')
        setNewDeckName('')
      }
    }, [id, newDeckName])

    const onRemoveOrDeleteHandler = useCallback(async () => {
      setDeleteModal(false)

      if (permission === DeckPermissions.READ) {
        const status = await deleteDeckCollabrator(id, userId!, workspaceId)
        if (status) {
          dispatch(setDeleteDeck({ deckId: id }))
          success('dashboard.card.deck_removed')
        }
      } else {
        const status = await deleteDeck({ deckId: id })
        if (status) {
          success('dashboard.card.deck_deleted')
        }
      }
    }, [id, permission, userId, workspaceId])

    const handlePlayClick = useCallback((id: number) => {
      window.open(`/preview/${id}/1`, '_blank', 'noopener,noreferrer')
    }, [])

    const handleEditClick = useCallback((id: number) => {
      window.open(`/deck/${id}`, '_blank')
    }, [])

    const handleDuplicate = useCallback(
      async (id: number) => {
        setIsDuplicating(true)
        const status = await duplicateDeck({ id, workspaceId })
        if (status) {
          setIsDuplicating(false)
        }
      },
      [workspaceId],
    )

    const handleShareClick = useCallback(() => {
      setShareModal(true)
    }, [])

    const handleAnalyticsClick = useCallback(() => {
      setSelectedOption('analytics')

      if ((userPlan === PlanNames.PRO || !userPlan) && !workspaceId) {
        setIsUpgradeModalOpen(true)
        return
      }
      setAnalyticsModal(true)
    }, [userPlan, workspaceId])

    const handleResponseClick = useCallback(() => {
      setSelectedOption('response')

      if ((userPlan === PlanNames.PRO || !userPlan) && !workspaceId) {
        setIsUpgradeModalOpen(true)
        return
      }
      setFormResponsesModal(true)
    }, [userPlan, workspaceId])

    const folderListItems = useCallback(
      (folderDeck: { folder: { id: number } } | null) => {
        const items = folderList.map((folder) => {
          return {
            icon: icons.form,
            label: folder.name,
            hidden: folder.id === folderDeck?.folder.id,
            onClick: () => {
              moveDeck(
                {
                  organizationId: workspaceId,
                  folderId: folder.id,
                },
                { deckId: id },
                folder.name,
              )
            },
          }
        })

        const folderItems = [
          {
            icon: icons.home_page,
            label: t('common.dashboard'),
            hidden: !folderDeck,
            onClick: () => {
              moveDeck(
                {
                  folderId: undefined,
                  organizationId: workspaceId,
                },
                { deckId: id },
              )
            },
          },
          ...(workspaceId
            ? // For now only Private-Organization transfers are valid, change here when Teams logic done
              [
                {
                  // TODO Change icon
                  icon: icons.add,
                  label:
                    viewMode === ViewModes.PRIVATE
                      ? t('common.organization')
                      : t('common.personal'),
                  hidden: false,
                  onClick: () => {
                    updateDeck(
                      { deckId: id },
                      {
                        organizationId: workspaceId,
                        viewMode:
                          viewMode === ViewModes.PRIVATE
                            ? ViewModes.ORGANIZATION
                            : ViewModes.PRIVATE,
                        deckPermission:
                          viewMode === ViewModes.PRIVATE
                            ? DeckPermissions.READ
                            : undefined,
                      },
                      undefined,
                      {
                        replaceDeck: true,
                      },
                    )
                  },
                },
              ]
            : []),
          ...items,
        ]
        return folderItems
      },
      [folderList, id, workspaceId],
    )

    const pivotDeck = useMemo(() => {
      return data.deck.pivotDeck
    }, [data])

    const isPivotDeckHidden = useMemo(() => {
      return pivotDeck?.isHidden
    }, [data, pivotDeck])

    const publishPivotDeckHandler = useCallback(async () => {
      const res = await publishPivotDeck({
        id,
        workspaceId,
        isHidden: isPivotDeckHidden,
      })
      if (res) {
        if (isPivotDeckHidden === undefined) {
          success(t('dashboard.publish.success'))
          return
        }
        success(
          isPivotDeckHidden
            ? t('dashboard.publish.success')
            : t('dashboard.unpublish.success'),
        )
      } else {
        if (isPivotDeckHidden === undefined) {
          error(t('dashboard.publish.error'))
          return
        }
        error(
          isPivotDeckHidden
            ? t('dashboard.publish.error')
            : t('dashboard.unpublish.error'),
        )
      }
    }, [id, workspaceId, isPivotDeckHidden])

    const isDecktopusAdmin = useMemo(() => {
      const isUserAdmin = orgUsers?.find(
        (org) => org.organization.id === DECKTOPUS_WORKSPACE_ID,
      )?.isAdmin

      return DECKTOPUS_WORKSPACE_ID === workspaceId && isUserAdmin
    }, [workspaceId])

    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
    const upgradeModalCallback = useCallback(() => {
      if (selectedOption === 'response') {
        setFormResponsesModal(true)
      } else if (selectedOption === 'analytics') {
        setAnalyticsModal(true)
      }
      setIsUpgradeModalOpen(false)
    }, [selectedOption])

    const menuItems = useMemo(() => {
      return [
        {
          label: t('common.actions.share'),
          icon: icons.share,
          hidden: permission === DeckPermissions.READ,
          onClick: () => handleShareClick(),
        },
        {
          label: t('common.actions.edit'),
          icon: icons.edit,
          hidden: permission === DeckPermissions.READ,
          onClick: () => handleEditClick(id),
        },
        {
          label: t('common.actions.rename'),
          icon: icons.edit_property,
          hidden: permission === DeckPermissions.READ,
          onClick: () => setRenameModal(true),
        },
        {
          label: t('common.actions.move_to'),
          icon: icons.form,
          items: folderListItems(folderDeck).filter(({ hidden }) => !hidden),
          hidden:
            folderListItems.length === 0 || permission === DeckPermissions.READ,
        },
        {
          label: t('common.analytics'),
          hidden: permission === DeckPermissions.READ,
          icon: icons.web_analytics,
          onClick: () => handleAnalyticsClick(),
        },
        {
          label: t('common.responses'),
          icon: icons.response,
          hidden: permission === DeckPermissions.READ,
          onClick: () => handleResponseClick(),
        },
        {
          label: t('common.actions.play'),
          icon: icons.play,
          onClick: () => handlePlayClick(id),
        },
        {
          label: t('common.actions.duplicate'),
          icon: icons.duplicate,
          onClick: () => handleDuplicate(id),
        },
        {
          label:
            isPivotDeckHidden === false
              ? t('dashboard.card.unpublish_template')
              : t('dashboard.card.publish_template'),
          icon: icons.duplicate,
          hidden: !isDecktopusAdmin,
          onClick: () => publishPivotDeckHandler(),
        },
        permission === DeckPermissions.READ
          ? {
              label: t('dashboard.card.remove_me'),
              icon: icons.trash_can,
              onClick: () => setDeleteModal(true),
              color: theme.colors.error.DEFAULT,
            }
          : {
              label: t('common.actions.delete'),
              icon: icons.trash_can,
              onClick: () => setDeleteModal(true),
              color: theme.colors.error.DEFAULT,
              hidden: !!pivotDeck,
            },
      ]
    }, [folderListItems, isPivotDeckHidden, pivotDeck])

    const primaryButtons = useMemo(() => {
      if (permission === DeckPermissions.READ)
        return [
          <Button
            text={t('common.actions.play')}
            onClick={() => handlePlayClick(id)}
            icon={icons.play}
            key="play"
            isRounded
            size={BUTTON_SIZE.SMALL}
          />,
        ]
      return [
        <Button
          text={t('common.actions.edit')}
          icon={icons.edit}
          size={BUTTON_SIZE.SMALL}
          isRounded
          key="edit"
          onClick={() => handleEditClick(id)}
        />,
      ]
    }, [])

    const newDeckButtons = useMemo(() => {
      return [
        <Button
          text={t('common.actions.lets_start')}
          icon={icons.sparkling}
          theme={BUTTON_THEME.GRADIENT}
          size={BUTTON_SIZE.SMALL}
          isRounded
          key="edit"
          onClick={() => handleEditClick(id)}
        />,
      ]
    }, [])

    const secondaryButtons = useMemo(() => {
      if (permission === DeckPermissions.READ) return []
      return [
        <Button
          text={t('common.actions.play')}
          onClick={() => handlePlayClick(id)}
          theme={BUTTON_THEME.WHITE}
          type={BUTTON_TYPE.GHOST}
          icon={icons.play}
          key="play"
          isLink
        />,
        <Button
          text={t('common.actions.share')}
          onClick={() => setShareModal(true)}
          theme={BUTTON_THEME.WHITE}
          type={BUTTON_TYPE.GHOST}
          icon={icons.share}
          key="share"
          isLink
        />,
      ]
    }, [])

    const isCardLoading = useMemo(() => {
      return (
        isDuplicating ||
        state === DeckStates.IN_QUEUE ||
        state === DeckStates.PROCESSING
      )
    }, [state, isDuplicating])

    const isDeckHasError = useMemo(() => {
      return state === DeckStates.ERRORED
    }, [state])

    const isDeckReadyForTransfer = useMemo(() => {
      return state === DeckStates.READY_FOR_TRANSFER
    }, [state])

    const isNewDeck = useMemo(() => {
      return !data.deck.isInitialized
    }, [data])

    const publishButton = useMemo(() => {
      if (!isDecktopusAdmin) {
        return
      }

      return (
        <Tooltip
          text={
            isPivotDeckHidden === false
              ? t('dashboard.card.unpublish_template')
              : t('dashboard.card.publish_template')
          }
        >
          <div css={pivotIconStyles}>
            <Icon
              icon={icons.template}
              color={
                isPivotDeckHidden === false
                  ? colors.primary.DEFAULT
                  : colors.deactive.DEFAULT
              }
              size={18}
              onClick={publishPivotDeckHandler}
            />
          </div>
        </Tooltip>
      )
    }, [isDecktopusAdmin, pivotDeck, isPivotDeckHidden])

    return (
      <div css={dashboardCardStyles} className={className} {...dataAttr}>
        {isUpgradeModalOpen && (
          <UpgradeModalManager
            key={selectedOption}
            isOpen={isUpgradeModalOpen}
            callback={upgradeModalCallback}
            context={ACTION_CLICKS.ANALYTICS_CLICK}
            availablePlanNames={[PlanNames.BUSINESS]}
            onClose={() => setIsUpgradeModalOpen(false)}
          />
        )}
        <Card
          id={id}
          hasError={isDeckHasError}
          onClickDelete={() => setDeleteModal(true)}
          isLoading={isCardLoading}
          title={title}
          subtitle={subtitle}
          subtitleInfo={subtitleInfo}
          footerIcon={footerIcon}
          footerText={footerText}
          footerTextInfo={footerTextInfo}
          avatar={avatar}
          menuItems={menuItems}
          primaryButtons={primaryButtons}
          secondaryButtons={secondaryButtons}
          thumbnailUrl={thumbnailUrl}
          isNewDeck={!isCardLoading && isNewDeck}
          newDeckButtons={newDeckButtons}
          isDeckReadyForTransfer={isDeckReadyForTransfer}
          isDeckFinal={state === DeckStates.FINAL}
          publishButton={publishButton}
        />
        <Modal isOpen={shareModal} onClose={() => setShareModal(false)}>
          {shareModal ? (
            <ShareView deckId={id} onClickClose={() => setShareModal(false)} />
          ) : (
            <></>
          )}
        </Modal>
        <Modal
          isOpen={analyticsModal}
          mobileVerticalAlign={true}
          onClose={() => setAnalyticsModal(false)}
        >
          <Analytics
            deckId={id}
            onClickShare={() => setShareModal(true)}
            onClickClose={() => setAnalyticsModal(false)}
          />
        </Modal>
        <Modal
          isOpen={formResponsesModal}
          mobileVerticalAlign={true}
          onClose={() => setFormResponsesModal(false)}
        >
          <FormResponses
            deckId={id}
            onClickClose={() => setFormResponsesModal(false)}
            onClickShare={() => setShareModal(true)}
          />
        </Modal>
        <Modal isOpen={renameModal} onClose={() => setRenameModal(false)}>
          <Dialog
            title={t('dashboard.card.rename_deck')}
            submitLabel={t('common.actions.rename')}
            onSubmit={onRenameHandler}
            promptValue={newDeckName}
            promptPlaceholder={t('dashboard.card.type_deck_name')}
            onPromptChange={setNewDeckName}
            onCloseClick={() => setRenameModal(false)}
            promptValidationRules={{
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  value: 3,
                  name: t('dashboard.card.deck_name'),
                }),
              },
            }}
          />
        </Modal>
        <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
          <Dialog
            title={
              permission === DeckPermissions.READ
                ? t('dashboard.card.remove_me')
                : t('common.actions.delete')
            }
            submitLabel={
              permission === DeckPermissions.READ
                ? t('dashboard.card.remove_me')
                : t('common.actions.delete')
            }
            submitTheme={BUTTON_THEME.RED}
            text={
              permission === DeckPermissions.READ
                ? [t('dashboard.card.remove_me_text', { deckName: title })]
                : [
                    t('dashboard.card.deck_delete_text', { deckName: title }),
                    t('dashboard.card.file_not_recover'),
                  ]
            }
            onSubmit={onRemoveOrDeleteHandler}
            onCloseClick={() => setDeleteModal(false)}
          />
        </Modal>
      </div>
    )
  },
)

DashboardCard.displayName = 'DashboardCard'
