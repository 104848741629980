import React, { useEffect, useState } from 'react'

import { IAnimationBar } from './types'
import { animationBarStyles, progressBarStyles } from './styles'

export const AnimationBar: React.FC<IAnimationBar> = React.memo(
  ({ completed, className, dataAttr }) => {
    const [progress, setProgress] = useState(0) // Progress state, from 0 to 100

    useEffect(() => {
      const updateProgress = () => {
        setProgress((prev) => (prev >= 100 ? 100 : prev + 0.1)) // Increment progress by 0.1 every 60ms
      }

      const intervalId = setInterval(updateProgress, 60) // Run updateProgress every 60ms

      // Stop the animation after 60 seconds
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId) // Stop updating progress
      }, 60000) // 60,000 milliseconds = 60 seconds

      if (completed) {
        clearInterval(intervalId) // Cleanup on unmount
        clearTimeout(timeoutId) // Cleanup timeout
      }

      return () => {
        clearInterval(intervalId) // Cleanup on unmount
        clearTimeout(timeoutId) // Cleanup timeout
      }
    }, [completed])

    useEffect(() => {
      if (completed) {
        setProgress(100)
      }
    }, [completed])

    return (
      <div className={className} {...dataAttr} css={animationBarStyles}>
        <div css={progressBarStyles} style={{ width: `${progress}%` }} />
      </div>
    )
  },
)

AnimationBar.displayName = 'AnimationBar'
