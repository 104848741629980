import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import iziToast from 'izitoast'
import i18n from 'i18next'
import { theme } from 'src/theme'
import { getRefreshTokenResponse } from 'src/types/api/responseObjects'
import { API_CONFIG, APP_CONFIG } from 'src/config'
import { DateTimeServices } from 'src/services'
import env from 'react-dotenv'
import { ErrorDefinitions } from 'src/types/api/ErrorDefinitions'
import { ILoginType } from 'src/components/login-form/types'

export type RequestResponse<T, K> = AxiosResponse<T, K>

const localToken = localStorage.getItem('token')

export const extRequest = axios.create()

export const request = axios.create({
  baseURL: env?.BASE_URL,
  headers: {
    common: {
      ...(localToken ? { Authorization: `Bearer ${localToken}` } : {}),
    },
  },
})

interface RetryConfig extends AxiosRequestConfig {
  retry: number
  retryDelay: number
}

const globalConfig: RetryConfig = {
  retry: APP_CONFIG.requestRetry.count,
  retryDelay: APP_CONFIG.requestRetry.delay,
}

request.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config } = error

    try {
      // 400 incorrect auth provider
      if (
        [400].includes(error?.response?.status) &&
        error?.response?.data?.message === ErrorDefinitions.INCORRECT_PROVIDER
      ) {
        const loginType = sessionStorage.getItem('loginType')
        showErrorMessage(
          i18n.t(
            loginType === ILoginType.GOOGLE
              ? 'login.incorrect_provider_jwt'
              : 'login.incorrect_provider_google',
          ),
        )
        return Promise.reject(false)
      }

      // 401 session expired
      if ([401].includes(error?.response?.status)) {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenCreatedAt')
        localStorage.removeItem('persist:workspace')
        if (
          !window.location.href.includes('login') &&
          localStorage.getItem('try') !== 'ai' &&
          localStorage.getItem('try') !== 'import'
        ) {
          window.location.href = `/login?session=expired`
        }

        return Promise.reject(false)
      }

      if (
        [500].includes(error?.response?.status) &&
        error?.response?.data?.message ===
          ErrorDefinitions.VALIDATION_EMAIL_MALFORMED
      ) {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenCreatedAt')
        localStorage.removeItem('persist:workspace')
        if (
          !window.location.href.includes('login') &&
          localStorage.getItem('try') !== 'ai' &&
          localStorage.getItem('try') !== 'import'
        ) {
          window.location.href = '/login'
        }
        return Promise.reject(false)
      }

      if (
        config &&
        config.retry &&
        [501, 502, 503].includes(error?.response?.status)
      ) {
        config.retry -= 1

        const delayRetryRequest = new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve()
          }, config.retryDelay || 1000)
        })

        return delayRetryRequest.then(() => request(config))
      }
    } catch {
      console.error('Request services error')
    }

    showErrorMessage(i18n.t(error?.response?.data?.message))
    return Promise.reject(error)
  },
)

export const showErrorMessage = (message: string): void => {
  iziToast.show({
    class: 'toaster',
    message: message,
    timeout: 3000,
    backgroundColor: theme.colors.error[90],
    messageColor: theme.colors.white.DEFAULT,
    animateInside: false,
    progressBar: false,
    close: false,
  })
}

export const setAuthHeader = (token: string): void => {
  request.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const deleteAuthHeader = () => {
  delete request.defaults.headers.common['Authorization']
}

export const callApi = async (
  config: AxiosRequestConfig<any>,
): Promise<AxiosResponse> => {
  const localTokenCreated = localStorage.getItem('tokenCreatedAt') || ''

  const isClientExpired = DateTimeServices.isTokenExpired(localTokenCreated)

  if (isClientExpired) {
    try {
      const res: RequestResponse<getRefreshTokenResponse, any> = await request({
        method: 'GET',
        url: API_CONFIG.AUTH_REFRESH,
      })

      request.defaults.headers.common['Authorization'] =
        'Bearer ' + res.data.data.authToken

      localStorage.setItem('token', res.data.data.authToken)
      localStorage.setItem('tokenCreatedAt', DateTimeServices.now())

      return request(config)
    } catch {
      localStorage.removeItem('token')
      localStorage.removeItem('tokenCreatedAt')
      localStorage.removeItem('persist:workspace')

      if (
        !window.location.href.includes('login') &&
        localStorage.getItem('try') !== 'ai' &&
        localStorage.getItem('try') !== 'import'
      ) {
        window.location.replace('/login?session=expired')
      }
    }
  }

  return request({ ...config, ...globalConfig })
}
