import { css } from '@emotion/react'
import { APP_CONFIG } from 'src/config'
import { ITheme } from 'src/theme'

export const editPageStyles = ({ colors }: ITheme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 !important;
  border: none !important;

  .editor {
    background: ${colors.background.DEFAULT};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .edit-area-wrapper {
      flex-grow: 1;
      position: relative;
    }

    .edit-area {
      flex-grow: 1;
      position: relative;

      .edit-area-inner {
        height: 100%;
        width: 100%;
        padding: ${APP_CONFIG.editPage.showToolbar ? 60 : 24}px 24px 24px 24px;
      }

      .toolbar {
        background: ${colors.white.DEFAULT};
        border: none;
        border-top: 1px solid ${colors.elements.DEFAULT};
        position: absolute;
        height: 48px;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;

        .quill-buttons {
          border: none;
        }
      }
    }
  }
`

export const previewPageWrapperStyles = (theme: ITheme) => css`
  width: 100%;
  height: 100%;
  background: ${theme.colors.black['80']};
`

export const headerStyles =
  ({
    isFullScreen,
    isExportMode,
  }: {
    isFullScreen: boolean
    isExportMode: boolean
  }) =>
  (theme: ITheme) => css`
    ${isExportMode ? 'display: none' : 'display: flex'};
    align-items: center;
    justify-content: space-between;
    gap: ${theme.gap['xsmall']}px;
    background-color: ${theme.colors.black.DEFAULT};
    width: 100%;
    height: ${theme.dimensions.previewHeaderFooter.DEFAULT}px;
    position: fixed;
    top: 0;
    color: ${theme.colors.white.DEFAULT};
    padding: 0 ${theme.spacing.xlarge};
    opacity: ${isFullScreen ? '0' : '1'};
    transition: opacity 0.3s;
    z-index: ${theme.zIndex.slideHeaderFooter};

    & .deck-name {
      font-size: ${theme.fontSize.large};
      font-weight: ${theme.fontWeight.medium};
    }

    & .voice {
      font-size: ${theme.fontSize.medium};
      font-weight: ${theme.fontWeight.medium};

      & .voice-recording-no-upload {
        background-color: transparent !important;
        color: white;
        padding: 0 !important;

        &:hover {
          background: transparent !important;
        }
      }
    }

    &:hover {
      opacity: 1;
    }
  `

export const slideStyles =
  ({
    isFullScreen,
    isExportMode,
  }: {
    isFullScreen: boolean
    isExportMode: boolean
  }) =>
  (theme: ITheme) => css`
    transition: all ease-in-out 0.3s;
    width: 100%;
    height: 100%;
    padding-top: ${isFullScreen || isExportMode
      ? 0
      : theme.dimensions.previewHeaderFooter.DEFAULT}px;
    padding-bottom: ${isFullScreen || isExportMode
      ? 0
      : theme.dimensions.previewHeaderFooter.DEFAULT}px;

    & .preview-edit-slides-styles {
      display: none;
    }
  `

export const footerStyles =
  ({
    isFullScreen,
    isExportMode,
  }: {
    isFullScreen: boolean
    isExportMode: boolean
  }) =>
  (theme: ITheme) => css`
    ${isExportMode ? 'display: none' : 'display: flex'};
    align-items: center;
    justify-content: space-between;
    gap: ${theme.gap['xsmall']}px;
    background-color: ${theme.colors.black.DEFAULT};
    width: 100%;
    height: ${theme.dimensions.previewHeaderFooter.DEFAULT}px;
    position: fixed;
    bottom: 0;
    padding: 0 ${theme.spacing.xlarge};
    opacity: ${isFullScreen ? '0' : '1'};
    transition: opacity 0.3s;
    z-index: ${theme.zIndex.slideHeaderFooter};

    &:hover {
      opacity: 1;
    }

    & .watermark {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    & .slide-control {
      background: ${theme.colors.black.DEFAULT};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.white.DEFAULT};
      font-size: ${theme.fontSize.large};
      font-weight: ${theme.fontWeight.medium};
      gap: ${theme.gap['3xsmall']}px;

      input {
        width: 28px;
        text-align: right;
        background: transparent;
        color: ${theme.colors.white.DEFAULT};
        font-weight: ${theme.fontWeight.medium};
        border: none;
        outline: none;
        font-size: ${theme.fontSize.large};
      }

      .max-slides {
        width: 28px;
      }
    }

    & .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  `

export const slideNavigations =
  ({
    direction,
    isFullScreen,
    isExportMode,
  }: {
    direction: string
    isFullScreen: boolean
    isExportMode: boolean
  }) =>
  (theme: ITheme) => css`
    ${isExportMode ? 'display: none' : 'display: flex'};
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100px;
    height: calc(100vh - ${theme.dimensions.previewHeaderFooter.DEFAULT * 2}px);
    top: ${theme.dimensions.previewHeaderFooter.DEFAULT}px;
    ${direction}: 0;
    z-index: ${theme.zIndex.icon};

    &:hover {
      & .navigation-area {
        opacity: 1 !important;
      }
    }

    & .navigation-area {
      background-color: ${theme.colors.black.DEFAULT};
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${theme.dimensions.slideNavigationButtons.DEFAULT}px;
      height: ${theme.dimensions.slideNavigationButtons.DEFAULT}px;
      cursor: pointer;
      border-radius: ${theme.borderRadius.DEFAULT};
      opacity: ${isFullScreen ? '0' : '1'};
      z-index: ${theme.zIndex.icon};
    }
  `

export const audioRowStyles = (isActive: boolean) => (theme: ITheme) => css`
  display: flex;
  height: 38px;
  align-items: center;
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  gap: ${theme.gap['2xsmall']}px;
  padding: ${theme.gap.xsmall}px ${theme.gap.small}px;
  color: ${isActive ? theme.colors.text.DEFAULT : theme.colors.text[2]};
  cursor: ${isActive ? 'pointer' : 'not-allowed'};
  &:hover {
    background: ${theme.gradient.VOICE};
  }
`

export const selectionRectangleStyles = css`
  position: fixed;
  border: 1px dashed #000;
  background-color: rgba(0, 0, 0, 0.1);
  display: none;
  pointer-events: none;
  z-index: 10000;
`
