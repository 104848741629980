export const texts = {
  // Common
  'common.decktopus': 'Decktopus',
  'common.decks': 'Folien',
  'common.free': 'Kostenlos',
  'common.description': 'Beschreibung',
  'common.name': 'Name',
  'common.email': 'E-Mail',
  'common.full_name': 'vollständiger Name',
  'common.role': 'Rolle',
  'common.dashboard': 'Dashboard',
  'common.analytics': 'Analytik',
  'common.responses': 'Antworten',
  'common.read_only': 'Nur Lesen',
  'common.read_write': 'Lesen & Schreiben',
  'common.last': 'Letzte',
  'common.first': 'Erste',
  'common.title_asc': 'Titel (A-Z)',
  'common.title_desc': 'Titel (Z-A)',
  'common.recent': 'zuletzt geändert',
  'common.oldest': 'älteste Änderung',
  'common.personal': 'Persönlich',
  'common.date': 'Datum',
  'common.auto': 'Automatisch',
  'common.rate': 'Bewerten',
  'common.text': 'Text',
  'common.value': 'Wert',
  'common.empty': 'Leer',
  'common.or': 'oder',
  'common.and': 'und',
  'common.email_address': 'E-Mail-Adresse',
  'common.password': 'Passwort',
  'common.organization': 'Organisation',
  'common.free_uppercased': 'KOSTENLOS',
  'common.design': 'Design',
  'common.access': 'Zugriff',
  'common.required': 'Erforderlich',
  'common.button': 'Knopf',
  'common.template_name': 'Vorlagenname',
  'common.language': 'Sprache',
  'common.texts': 'Texte',

  'common.files': '{{count}} Dateien',
  'common.files_zero': '{{count}} Datei',
  'common.files_one': '{{count}} Datei',
  'common.files_other': '{{count}} Dateien',

  'common.slides': '{{count}} Folien',
  'common.slides_zero': '{{count}} Folie',
  'common.slides_one': '{{count}} Folie',
  'common.slides_other': '{{count}} Folien',

  'common.actions.change': 'Ändern',
  'common.actions.close': 'Schließen',
  'common.actions.complete': 'Fertigstellen',
  'common.actions.back': 'Zurück',
  'common.actions.reset': 'Zurücksetzen',
  'common.actions.delete': 'Löschen',
  'common.actions.create': 'Erstellen',
  'common.actions.share': 'Teilen',
  'common.actions.search_with_name': '{{name}} suchen',
  'common.actions.check': 'Prüfen',
  'common.actions.connect': 'Verbinden',
  'common.actions.export': 'Exportieren',
  'common.actions.download': 'Herunterladen',
  'common.actions.sort_by': 'Sortieren nach',
  'common.actions.edit': 'Bearbeiten',
  'common.actions.save': 'Speichern',
  'common.actions.cancel': 'Abbrechen',
  'common.actions.add': 'Hinzufügen',
  'common.actions.remove': 'Entfernen',
  'common.actions.rename': 'Umbenennen',
  'common.actions.discard': 'Verwerfen',
  'common.actions.approve': 'Genehmigen',
  'common.actions.next': 'Nächstes',
  'common.actions.move_to': 'Verschieben nach',
  'common.actions.play': 'Abspielen',
  'common.actions.duplicate': 'Duplizieren',
  'common.actions.activate': 'Aktivieren',
  'common.actions.save_changes': 'Änderungen speichern',
  'common.actions.paste': 'Einfügen',
  'common.actions.upload': 'Hochladen',
  'common.actions.submit': 'Einreichen',
  'common.actions.listen_voice': 'Stimme hören',
  'common.actions.record_voice': 'Stimme aufnehmen',
  'common.actions.lets_start': 'Lass uns anfangen',
  'common.actions.leave': 'Ausgang',
  'common.actions.upload_picture': 'Bild hochladen',
  'common.actions.remove_picture': 'Bild entfernen',

  'common.informative.copied': 'Kopiert!',
  'common.informative.click_here': 'klicken Sie hier.',
  'common.informative.coming_soon': 'Kommt bald',
  'common.informative.fail': 'Fail',
  'common.informative.processing': 'Prozess',
  'common.informative.thanks': 'Danke!',
  'common.informative.done': 'Fertig',
  'common.informative.payment_successful': 'Zahlung erfolgreich',
  'common.informative.your_deck_is_ready': 'Ihr Präsentation ist bereit.',
  'common.informative.ai_loading_text':
    'Erstelle Ihr Meisterwerk, einen Moment...',
  'common.informative.ai_error_text':
    'Wir erleben eine außergewöhnlich hohe Nachfrage.',
  'common.informative.wait_on_dashboard': 'Warte auf Dashboard',
  'common.informative.back_to_first': 'Zurück zum Ersten',
  'common.informative.ai_high_demand_text':
    'Ihr Präsentation konnte nicht erstellt werden. Wir erleben eine außergewöhnlich hohe Nachfrage.',
  'common.informative.error_start_recording':
    'Fehler beim Starten der Aufnahme:',
  'common.informative.error_stop_recording':
    'Fehler beim Stoppen der Aufnahme:',
  'common.informative.error_changing_language':
    'Problem beim Sprachwechsel aufgetreten, bitte kontaktieren Sie unseren Kundendienst',

  'common.directions.left': 'Links',
  'common.directions.middle': 'Mitte',
  'common.directions.right': 'Rechts',
  'common.directions.up': 'Oben',
  'common.directions.center': 'Zentrum',
  'common.directions.down': 'Unten',

  'common.nth': '{{count}}te',
  'common.nth_one': '{{count}}ste.',
  'common.nth_two': '{{count}}te.',
  'common.nth_three': '{{count}}te.',
  'common.nth_other': '{{count}}te',

  'common.files.media': 'Medien',
  'common.files.video': 'Video',
  'common.files.image': 'Bild',
  'common.files.gif': 'Gif',
  'common.files.icon': 'Symbol',
  'common.files.powerpoint': 'PowerPoint',
  'common.files.pdf': 'PDF',

  'common.roles.owner': 'Eigentümer',
  'common.roles.admin': 'Administrator',
  'common.roles.member': 'Mitglied',
  'common.roles.pending': 'Ausstehend',

  'common.plan_period.monthly': 'Monatlich',
  'common.plan_period.annually': 'Jährlich',

  'common.plan_card.month': ' / Monat',

  'common.seats': 'Sitz',
  'common.seats_zero': 'Sitze',
  'common.seats_one': 'Sitz',
  'common.seats_other': 'Sitze',

  'common.deck.add_slide': 'Folie hinzufügen',
  'common.deck.duplicate_slide': 'Folie duplizieren',
  'common.deck.delete_slide': 'Folie löschen',
  // Common ends

  // Login page
  'login.session_expired':
    'Sitzung abgelaufen, bitte melden Sie sich erneut an.',
  'login.continue_with_google': 'Mit Google fortfahren',
  'login.login': 'Anmelden',
  'login.logout': 'Abmelden',
  'login.signup': 'Registrieren',
  'login.dont_have_an_account': 'Kein Konto?',
  'login.forgot_password': 'Vergessen Sie Ihr Passwort?',

  // Expired page
  'expired.get_started': 'Loslegen',
  'expired.expire_link_title': 'Dieser Link ist abgelaufen',
  'expired.expire_link_description':
    'Dieser Link ist nicht mehr gültig. Bitte setzen Sie sich für weitere Informationen mit Ihrer Organisation in Verbindung.',
  // Expired page ends

  // Sign up page
  'signup.terms': 'Bedingungen',
  'signup.privacy_policy': 'Datenschutzrichtlinie',
  'signup.full_name': 'Vollständiger Name',
  'signup.already_member': 'Schon Mitglied?',
  'signup.create_account': 'Konto erstellen',
  'signup_page_title':
    'Sie sind nur einen Klick von Ihrer Präsentation entfernt',
  'signup.create_account_info':
    'Ein Konto zu erstellen bedeutet, dass Sie mit unseren <0>Bedingungen</0> und <1>Datenschutzrichtlinien</1> einverstanden sind',
  // Sign up page ends

  // Reset password
  'reset.password_reset': 'Passwort zurücksetzen',
  'reset.back_to': 'Zurück zu',
  'reset.send_email': 'E-Mail senden',
  'reset.resend': 'E-Mail erneut senden',
  'reset.new_password': 'Neues Passwort',
  'reset.password_set':
    'Ihr Passwort wurde geändert, bitte melden Sie sich an.',
  'reset.reset_password': 'Setzen Ihr Passwort zurück',
  'reset.reset_password_info':
    'Geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen die Anweisungen, wie Sie Ihr Passwort zurücksetzen können',
  'reset.password_reset_sent':
    'Ihre E-Mail zum Zurücksetzen des Passworts wurde gesendet',
  'reset.change_your_password_info':
    'Klicken Sie auf die Schaltfläche, um Ihr Passwort zurückzusetzen',
  // Reset password ends

  // Auth pages common
  'auth.site_protection': `Diese Site ist durch hCaptcha geschützt und es gelten deren <0>Datenschutzrichtlinie</0> und <1>Servicebedingungen</1>.`,
  'auth.terms_of_service': 'Servicebedingungen',
  // Auth pages common ends

  // Not supported page
  'not_supported': 'nicht unterstützt',
  'not_supported.title':
    'Leider wird der Bearbeitungsmodus auf diesem Gerät noch nicht unterstützt. Für das beste Erlebnis wechseln Sie bitte zu einem Desktop-Computer.',
  //

  // Webhook
  'webhook.endpoint_url': 'Ihre Endpunkt-URL',
  'webhook.event_form_responses': 'Ereignis: Formularantworten',
  'webhook.webhook_delete_warning':
    'Möchten Sie den „<strong>{{name}}</strong>” Webhook wirklich dauerhaft löschen?',
  'webhook.url': 'URL',
  'webhook.webhook': 'Webhook',
  'webhook.create_webhook': 'Webhook erstellen',
  'webhook.webhook_form_general_info':
    'Erstellen Sie einen Webhook und senden Sie Echtzeitbenachrichtigungen, wenn eines Ihrer Decks eine Formulareinreichung erhält. Fügen Sie Ihre Endpunkt-URL hinzu und benennen Sie Ihren neuen Webhook.',
  'webhook.webhook_learn':
    'Um mehr über die Verwendung von Webhooks zu erfahren, <0>klicken Sie hier.</0>',
  // Webhook ends

  // Main panel tabs
  'main_panel.admin': 'Admin',
  'main_panel.organization': 'Organisation',
  'main_panel.profile_settings': 'Profileinstellungen',
  'main_panel.webhook': 'Webhook',
  'main_panel.refer_and_earn': 'Empfehlen & Verdienen',
  'main_panel.subscription': 'Abonnement',
  'main_panel.onboarding': 'Onboarding',
  'main_panel.logout': 'Abmeldung',
  // Main panel tabs ends

  // Profile settings
  'profile.change_account_information': 'Kontoinformationen ändern',
  'profile.edit_your_name': 'Bearbeiten Sie Ihren Namen',
  'profile.change_your_password': 'Ändern Sie Ihr Passwort',
  'profile.update.save_changes': 'Änderungen speichern',
  'profile.update.image_upload_success': 'Ihr Profilbild wurde geändert.',
  'profile.update.name_change_success': 'Ihr Name wurde geändert.',
  'profile.update.name_min_char_error':
    'Der Name kann nicht kürzer als 3 Buchstabe sein.',
  'profile.update.image_upload_error': '{{fileType}} wird nicht unterstützt.',
  'profile.verification_success':
    'Sie haben Ihr Konto erfolgreich verifiziert.',
  // Profile settings ends

  // Verify email
  'verify.verify_your_email': 'Verifizieren Sie Ihre E-Mail',
  'verify.verify_your_account': 'Verifizieren Sie Ihr Konto',
  'verify.verify_your_email_info':
    'Klicken Sie auf die Schaltfläche, um Ihr Konto zu verifizieren.',
  'verify.verification_email_sent': 'Verifizierungs-E-Mail gesendet.',
  'verify.verification_enter_code': 'Geben Sie Ihren Code ein',
  'verify.verification_didnt_reveive_email':
    'E-Mail nicht erhalten? <0>Link</0>',
  'verify.verification_click_to_resend': 'Klicken, um erneut zu senden',
  'verify.verification_email_sent_info':
    'Wir haben Ihnen einen Verifizierungscode an Ihre E-Mail gesendet.',
  // Verify email ends

  // Validations
  'validation.min_char':
    '{{name, capitalFirstLetter}} kann nicht kürzer als {{value}} Buchstabe sein.',
  'validation.max_char':
    '{{name, capitalFirstLetter}} kann nicht länger als {{value}} Buchstabe sein.',
  'validation.error.required':
    '{{name, capitalFirstLetter}} darf nicht leer sein',
  'validation.error.email': 'Ungültige E-Mail-Adresse',
  'validation.error.url': 'Ungültige URL-Adresse',
  'validation.error.video_url':
    'Ungültige Video-URL. Bitte geben Sie einen YouTube- oder Vimeo-Link ein.',
  'validation.validation_user_exists': 'Dieser Benutzer existiert bereits',
  // Validations ends

  // Layout modal
  'layout.save': 'Layout speichern',
  'layout.save_new': 'Als neues Layout speichern',
  'layout.name': 'Layoutname',
  'layout.blank': 'Leer',

  'layout.267': 'Neues Cover (Test)',
  'layout.185': 'Cover',
  'layout.256': 'Text + Bild',
  'layout.94': 'Symbol + Text',
  'layout.257': 'Punkte-Liste + Bild',
  'layout.265': 'Symbolliste',
  'layout.199': 'Bildliste',
  'layout.258': 'Zeitstrahl',
  'layout.36': 'Video',
  'layout.96': 'Schaufenster',
  'layout.263': 'Nummer + Bild',
  'layout.264': 'Zitat + Bild',
  'layout.45': 'Formular',
  'layout.262': 'Liniendiagramm',
  'layout.261': 'Balkendiagramm',
  'layout.260': 'Kreisdiagramm',
  'layout.148': 'Referenzen',
  'layout.126': 'Punkte-Liste!',
  'layout.259': 'Tabelle',
  'layout.183': 'Website-URL einbetten',
  'layout.184': 'Code einbetten',
  'layout.90': 'Mehrere Logos',
  'layout.60': 'Aktionsknopf',
  'layout.180': 'Logo Text Bild',
  'layout.61': 'Links',
  // Layout modal ends

  // Workspace selector
  'workspace.personal_hub': 'Persönliches Hub',
  // Workspace selector ends

  // Init modal
  'init.category_select_info':
    'Wählen Sie eine Designkategorie, die zu Ihrem Präsentationskonzept passt',
  'init.see_templates': 'Templates ansehen',
  'init.start_from_scratch': 'bei Null anfangen',
  // Init modal ends

  // Create with AI modal
  'create_with_ai.generate_presentation': 'Generieren Ihre Präsentation ',
  'create_with_ai.create_with_ai': 'Mit AI erstellen',
  'create_with_ai.prompt_placeholder':
    'Schreiben Sie hier Ihr Präsentationsthema!',
  'create_with_ai.audience_placeholder': 'Schreiben Sie Ihre Zielgruppe',
  'create_with_ai.aim_placeholder': 'Schreiben Sie Ihr Ziel',
  'create_with_ai.prompt_title': 'Worum geht es in Ihrer Präsentation?',
  'create_with_ai.audience_title': 'Wer ist Ihre Zielgruppe?',
  'create_with_ai.aim_title': 'Was ist Ihr Ziel für dieses Präsentation?',
  'create_with_ai.outline_title':
    'Sie können den Überblick ändern oder ohne Änderungen fortfahren.',
  'create_with_ai.theme_title': 'Welche Template möchten Sie verwenden?',
  'create_with_ai.theme_subtitle':
    'Keine Sorge! Sie können es jederzeit ändern.',
  'create_with_ai.ai_subtitle':
    'Inspiration für Deckto mit einem Titel. Versuchen Sie, Ihr Präsentationsthema wie folgt einzugeben: „Geschichte Roms“',
  'create_with_ai.see_examples': 'Sehen Sie die Beispiele an',
  'create_with_ai.hide_examples': 'Blenden Sie die Beispiele aus',
  'create_with_ai.import_pdf': 'Importieren Sie Ihr PDF',
  'create_with_ai.pdf_subtitle':
    'Deckto verwandelt Ihr Dokument in eine Präsentation mit bearbeiteten Folien.',
  // Create with AI modal ends

  // Pdf import modal
  'pdf_import.import_pdf_file': 'Importieren Sie Ihr PDF',
  'pdf_import.drag_and_drop_file': 'Drag & Drop Ihrer Datei',
  'pdf_import.on_your_computer': 'auf Ihrem Computer',
  'pdf_import.browse_file': ' Datei durchsuchen ',
  'pdf_import.browse_file_on_your_device': 'Datei auf Ihrem Gerät durchsuchen',
  'pdf_import.pdf_size_error':
    'Die von Ihnen hochgeladene PDF-Datei ist viel größer, als wir unterstützen, versuchen Sie es mit einer kleineren.',
  'pdf_import.use_pdf_format':
    'Kann nicht hochgeladen werden. Verwenden Sie eine Datei im .PDF-Format.',
  'pdf_import.drop_the_file_to_upload': 'Legen Sie die Datei zum Hochladen ab',
  // Pdf import modal ends

  // Organization page
  'organization.organization_profile_update.image_upload_success':
    'Organisationsbild wurde geändert.',
  'organization.organization_profile_update.name_and_description_success':
    'Organisationsdetails wurden geändert.',
  'organization.organization_details': 'Organisationsdetails',
  'organization.remaining_seats': 'Verbleibende Sitze',
  'organization.manage_seats': 'Sitze verwalten',
  'organization.used_seats':
    '{{occupiedSeats}} von {{totalSeats}} Sitze verwendet',
  'organization.adjust_seat_number': 'Sitzplatzanzahl anpassen',
  'organization.current_users': 'Aktuelle Benutzer ({{count}}) Kosten / 12',
  'organization.added_users': 'Hinzugefügte Benutzer ({{count}}) Kosten / 12',
  'organization.updated_annual_total':
    'Aktualisierte {{period}} Gesamtkosten / 12',
  'organization.first_charge': 'Die erste Gebühr wird anteilig berechnet.',
  'organization.first_charge_tooltip':
    'Die erste Gebühr für die zusätzlichen Plätze wird entsprechend der verbleibenden Tage Ihres aktuellen Abrechnungszyklus berechnet.',
  'organization.reset_invitation': 'Einladung erneut senden',
  'organization.cancel_invitation': 'Einladung stornieren',
  'organization.member_settings': 'Mitgliedereinstellungen',
  'organization.add_members': 'Mitglieder hinzufügen',
  'organization.add_members_title':
    'Mitglieder zu Ihrer Organisation hinzufügen',
  'organization.not_enough_seats':
    'Sie haben nicht genügend Plätze, um weitere Mitglieder hinzuzufügen. Passen Sie die Sitzplatzanzahl auf der Organisationsseite an, um Platz zu schaffen.',
  'organization.add_member_information':
    'Sie können jederzeit Mitglieder zu Ihrer Organisation einladen, indem Sie auf die rechte Seitenleiste auf der Seite „Meine Organisation“ klicken.',
  'organization.organization-details': 'Organisationsdetails',
  'organization.leave_team_title': 'Team verlassen',
  'organization.leave_team_button': 'Die Organisation verlassen',
  'organization.leave_team_dialog_text':
    'Wenn Sie eine Organisation verlassen, können Sie nicht mehr auf kollaborative Decks zugreifen.',
  'organization.leave_team_text':
    'Wenn Sie das Team verlassen, können Sie nicht mehr auf die Inhalte und Geschäftsfunktionen der Organisation zugreifen.',
  'organization.organization_name_label': 'Organisationsname',
  'organization.organization_desc_label': 'Organisationsbeschreibung',
  'organization.organization_team_logo': 'Teamlogo',

  'organization.delete_member': 'Mitglied löschen',
  'organization.delete_member_title':
    'Möchten Sie das Mitglied „{{name}}“ wirklich dauerhaft löschen?',
  'organization.delete_member_text':
    '„{{name}}“ kann alle Decks und Organisationsdetails nicht mehr sehen',

  'organization.resend_info':
    '{{email}} hat Ihre Einladung noch nicht angenommen. Sie senden die Einladung als Erinnerung erneut.',
  'organization.organization_suspended':
    'Diese Organisation ist ausgesetzt. Bitte aktualisieren Sie Ihre Zahlungsmethode, um das Problem zu beheben.',
  'organization.organization_suspended_member':
    'Diese Organisation ist ausgesetzt. Bitte wenden Sie sich für weitere Unterstützung an den Organisationsadministrator.',
  // Organization page ends

  // Refer
  'refer.invite_friend': 'Laden Sie Ihren Freund ein!',
  'refer.invite_friend_text':
    'Sie und Ihr Freund erhalten beide <strong>30</strong> Credits.',
  'refer.referred': 'Wir haben Ihrem Freund eine E-Mail geschickt',
  // Refer ends

  // Analytics
  'analytics.no_views_yet': 'Noch keine Aussicht',
  'analytics.share_this_deck': 'Teilen Sie diese Präsentation.',
  'analytics.total_views': '{{view}} Gesamtansichten',
  'analytics.total_views_one_slide':
    '{{view}} Ansichten nur für die erste Folie',
  'analytics.chart_description':
    'Dieses Diagramm stellt die Leistung Ihrer Präsentationsansicht dar',
  'analytics.last_time_open': 'Zuletzt geöffnet',
  'analytics.views': '{{view}} Ansichten',
  'analytics.openedTimes': '{{view}} mal geöffnet',
  // Analytics ends

  // Subscriptions
  'subscriptions.upgrade': 'Upgrade',
  'subscriptions.show_more': 'Mehr anzeigen',
  'subscriptions.show_less': 'Weniger anzeigen',
  'subscriptions.subscription': 'Abonnement',
  'subscriptions.current_plan': 'Aktueller Plan',
  'subscriptions.credit_information': 'Kreditinformationen',
  'subscriptions.ai_credits': 'AI-Kredits',
  'subscriptions.choose_plan': 'Plan auswählen',
  'subscriptions.plan_renew_info': 'Ihr Plan wird am {{date}} erneuert',
  'subscriptions.plan_cancel_info': 'Ihr Plan wird am {{date}} gekündigt',
  'subscriptions.plan_renew_info_free':
    'Um erstaunliche AI-Funktionen zu nutzen, aktualisieren Sie Ihr Konto',
  'subscriptions.credit_renew_info': 'Ihre Credits werden am {{date}} erneuert',
  'subscriptions.credit_cancel_info':
    'Ihre Credits können bis zum {{date}} verwendet werden',
  'subscriptions.credit_history': 'Kreditverlauf',
  'subscriptions.credit_history_info':
    'Der Kreditverlauf spiegelt die Ausgaben bis zum Beginn des Abonnementzeitraums wider',
  'subscriptions.subscription_settings': 'Abonnement-Einstellungen',
  'subscriptions.plan.powerup_info':
    'Ich möchte mein {{plan_name}}-Paket aufladen',
  'subscriptions.plan.choose_pro': 'Pro wählen',
  'subscriptions.plan.choose_business': 'Geschäft wählen',
  'subscriptions.plan.discount_info': 'Sparen Sie bis zu 40%',

  'subscriptions.subs_state.pending': 'Ausstehend',
  'subscriptions.subs_state.action_required': 'Aktion erforderlich',
  'subscriptions.subs_state.failed': 'Fehlgeschlagen',
  'subscriptions.subs_state.canceled': 'Abgebrochen',
  'subscriptions.subs_state.active': 'Aktiv',
  'subscriptions.plan.popular': 'Beliebt',
  'subscriptions.billed_annually': ' jährlich abgerechnet',
  // Subscriptions ends

  // Upgrade modal
  'upgrade_modal.upgrade_to_business': 'Upgrade ins Geschäftskonto',
  'upgrade_modal.upgrade_to_business_text':
    'Steigern Sie Ihre Präsentationen und erhöhen Sie Ihren ROI bei Präsentationen.',
  'subscriptions.plan.cancel_anytime': 'Jederzeit kündbar',
  'upgrade_modal.refill_your_credits': 'Ihre Credits auffüllen',
  'upgrade_modal.upgrade_refill_modal_subtitle':
    'Halten Sie Ihr Tempo. Aktualisieren Sie Ihren Plan, um weiterarbeiten zu können.',
  'upgrade_modal.upgrade_modal_subtitle':
    'Nutzen Sie Decktopus als Ihren AI-Kopiloten für Präsentationen.',
  'upgrade_modal.choose_plan': 'Wählen Sie einen Plan',
  // Upgrade modal ends

  // Dashboard page
  'dashboard.create.create_presentation': 'Präsentation erstellen',
  'dashboard.create.start_from_scratch': 'bei Null anfangen',
  'dashboard.create.create_with_a_template': 'Mit einer Vorlage erstellen',

  'dashboard.folder.folder': 'Ordner',
  'dashboard.shared_with_me': 'Mit mir geteilt',
  'dashboard.folder.create_new_folder': 'Neuen Ordner erstellen',
  'dashboard.folder.create_folder': 'Ordner erstellen',
  'dashboard.folder.delete_title':
    'Sind Sie sicher, dass Sie „{{folder_name}}“ und {{folder_id}} Dateien darin dauerhaft löschen möchten?',
  'dashboard.folder.delete_text':
    'Dateien können nicht wiederhergestellt werden, sobald sie gelöscht wurden.',
  'dashboard.folder.folder_is_created': 'Ordner wurde erstellt.',
  'dashboard.folder.folder_deleted': 'Ordner wurde gelöscht.',
  'dashboard.folder.type_folder_name': 'Geben Sie hier Ihren Ordnernamen ein.',
  'dashboard.folder.rename': 'Ordner umbenennen',
  'dashboard.folder.renamed': 'Ordner wurde umbenannt.',
  'dashboard.folder.name': 'Ordnername',

  'dashboard.card.rename_deck': 'Präsentation umbenennen',
  'dashboard.card.transferring':
    'Ihre Präsentation wird von der alten Version übertragen...',
  'dashboard.card.migration': 'Migrieren',
  'dashboard.card.type_deck_name':
    'Geben Sie hier den Namen Ihrer Präsentation ein.',
  'dashboard.card.deck_name': 'Präsentationname',
  'dashboard.card.deck_renamed': 'Präsentation wurde umbenannt.',
  'dashboard.card.deck_delete_text':
    'Sind Sie sicher, dass Sie „{{deckName}}“ löschen möchten?',
  'dashboard.card.remove_me_text':
    'Sind Sie sicher, dass Sie sich selbst aus „{{deckName}}“ entfernen möchten?',
  'dashboard.card.remove_me': 'Mich entfernen',
  'dashboard.card.file_not_recover':
    'Dateien können nicht wiederhergestellt werden, sobald sie gelöscht wurden.',
  'dashboard.card.deck_deleted': 'Präsentation wurde gelöscht',
  'dashboard.card.deck_removed': 'Sie wurden aus der Präsentation entfernt',

  'deck_not_created': 'Präsentation konnte nicht erstellt werden.',

  'dashboard.search.search_my_decks':
    'Meine Präsentationen in Decktopus suchen',
  'dashboard.search.results_for': 'Ergebnisse für „{{searchParam}}“:',
  'dashboard.search.no_results_for':
    'Keine Ergebnisse gefunden, die mit <strong>{{ searchParam }}</strong> übereinstimmen',
  'dashboard.search.try_another_search':
    'Versuchen Sie, Ihre Suche anzupassen, um das Gesuchte zu finden',
  'dashboard.search.return_dashboard': 'oder kehren Sie zum Dashboard zurück',
  'dashboard.search.search_on_decktopus': 'Auf Decktopus suchen',
  // Dashboard page ends

  // Form responses
  'form_response.form_responses': 'Formularantworten',
  'form_response.table.download_form': 'Formular herunterladen',
  'form_response.table.deleted_question': 'Gelöschte Frage',
  'form_response.no_answers_yet': 'Noch keine Antworten.',
  'form_response.table.avarage_score': 'Durchschnittsbewertung',
  // Form responses end

  // Edit page
  'edit.slide_menu.layout': 'Layout',

  'edit.create_slide.no_custom_layout_text': `Sie haben noch kein benutzerdefiniertes Layout erstellt.`,
  'edit.create_slide.no_shared_layout_text': `Sie haben keine gemeinsam genutzten Layouts.`,

  'edit.color_picker.select_color': 'Wählen Sie die Farbe aus',
  'edit.color_picker.solid': 'Vollfarbe',
  'edit.color_picker.gradient': 'Gradient',
  'edit.color_picker.theme_colors': 'Themenfarben',
  'edit.color_picker.recent_colors': 'Aktuelle Farben',

  'edit.media.modal.title': '{{type}} aus der Bibliothek auswählen',
  'edit.media.modal.load_more': 'Mehr laden',
  'edit.media.modal.no_result': 'Kein Ergebnis',
  'edit.media.modal.initial_text':
    'Bitte geben Sie einen Text ein, um {{type}} zu suchen',
  'edit.media.modal.ai.input': 'Beschreibung',
  'edit.media.modal.select_media': '{{ type }} auswählen',
  'edit.media.modal.search_media': '{{ type }} suchen',

  'edit.list.submenu.placeholder': 'mehrere',
  'edit.list.layout.columns': 'Spalten',
  'edit.list.layout.rows': 'Reihen',

  'edit.create_slide.copy_from_another_deck':
    'Aus einer anderen Präsentation kopieren',
  'edit.header.title': 'Bearbeiten Sie die Header-Komponente',

  'edit.elements.embeds': 'Einbettungen',
  'edit.elements.interactive_elements': 'Interaktive Elemente',
  'edit.elements.see_all': 'Alle anzeigen',
  'edit.elements.see_less': 'Weniger anzeigen',
  'edit.elements.shapes': 'Formen',
  'edit.elements.tooltip_text':
    'Es kann nur ein Formular zu einer Folie hinzugefügt werden',
  'edit.elements.content_blocks': 'Inhaltsblöcke',

  'edit.canvas.context_menu.bring_forward': 'Nach vorne bringen',
  'edit.canvas.context_menu.send_backward': 'Nach hinten senden',
  'edit.canvas.context_menu.bring_to_front': 'In den Vordergrund bringen',
  'edit.canvas.context_menu.send_to_back': 'In den Hintergrund senden',
  'edit.canvas.context_menu.send_to_background': 'Als Hintergrund senden',
  'edit.canvas.paste.processing_image': 'Bild wird verarbeitet...',
  'edit.canvas.paste.image_pasted': 'Bild hochgeladen!',

  'edit.ai.create_qa_text':
    'Sehen Sie sich die Q&As an, die für Ihre Präsentation spezialisiert sind. Die Fragen, die während Ihrer Präsentation gestellt werden könnten, und ihre Antworten befinden sich in diesem Abschnitt.',
  'edit.ai.create_qa_button': 'Q&A erstellen',
  'edit.ai.create_tip_text':
    'Folientipps befinden sich in diesem Abschnitt, um eine bessere Präsentationserfahrung zu bieten. Vorschläge für Eisbrecher, Haken und eine geschichtsähnliche Struktur finden Sie hier, um Ihnen zu helfen.',
  'edit.ai.create_tip_button': 'Tipps erstellen',
  'edit.ai.create_tips_hover':
    'Erstellen Sie ein AI-Folien, um diese Funktion zu nutzen.',

  'edit.canvas_sidebar.start_show': 'Starten die Show ',
  'edit.canvas_sidebar.start_voice': 'Starten die Stimme',
  'edit.canvas_sidebar.swap_colors': 'Austauschen der Farben',

  'edit.design.customize_design': 'Design anpassen',
  'edit.design.font_family': 'Schriftfamilie',
  'edit.design.default_fonts_delete':
    'Standardschriften können nicht gelöscht werden.',
  'edit.design.upload_custom_font': 'Benutzerdefinierte Schrift hochladen',
  'edit.design.upload_your_font': 'Laden Sie Ihre Schrift hier hoch',
  'edit.design.allowed_fonts_text':
    'Bitte laden Sie nur Schriftarten im Format .ttf und .otf hoch*.',
  'edit.design.custom_font_responsibility':
    '*Alle Verantwortung für die kommerzielle Nutzung der hochgeladenen Schriftarten liegt beim Benutzer. Decktopus übernimmt keine Haftung für die kommerzielle Nutzung.',
  'edit.design.upload_primary_font': 'Primärschrift hochladen',
  'edit.design.upload_secondary_font': 'Sekundärschrift hochladen',
  'edit.design.color_palette': 'Farbpalette',
  'edit.design.default_color_palettes':
    'Standardpaletten können nicht gelöscht werden.',

  'edit.preferences.background_color': 'Hintergrundfarbe',
  'edit.preferences.background_decor': 'Hintergrundoptionen',

  'edit.properties.list.submenu.title': 'Text- & Icon-Eigenschaften',
  'edit.properties.list.new_list.add_new': 'Neu hinzufügen',

  'edit.properties.quote.placeholder': 'Zitat',
  'edit.properties.quote.author': 'Autor',

  'edit.properties.media.image_props': 'Bildeigenschaften',

  'edit.properties.icon.styles': 'Stile',
  'edit.properties.icon.icon': 'Symbol',
  'edit.properties.icon.background': 'Hintergrund',

  'edit.properties.data': 'Daten bearbeiten',
  'edit.properties.table': 'Tabelle bearbeiten',
  'edit.properties.show_header_column': 'Kopfzeilenspalte anzeigen',
  'edit.properties.show_header_row': 'Kopfzeile anzeigen',
  'edit.properties.form.submenu.title': 'Formulareigenschaften',
  'edit.properties.form.button_text': 'Schaltflächentext',
  'edit.properties.form.placeholder_text': 'Platzhaltertext',
  'edit.properties.form.question_text': 'Fragetext',
  'edit.properties.form.choice': 'Auswahl',
  'edit.properties.form.header': 'Formulartyp hier hinzufügen',
  'edit.properties.form.header.button_text': 'Schaltflächentext',
  'edit.properties.form.option_list': 'Optionsliste',
  'edit.properties.form.check_list': 'Prüfliste',
  'edit.properties.form.free_text': 'Freitext',
  'edit.properties.form.submitting_required_tooltip':
    'Wenn dies aktiviert ist, kann der Betrachter die nächsten Folien nicht sehen, bis dieses Formular eingereicht wurde',

  'edit.properties.button_props.paste_url': 'Fügen Sie eine Website-URL ein.',
  'edit.properties.button_props.outlined': 'Umrissen',

  'edit.properties.chart_props.show_grid': 'Gitterlinien anzeigen',
  'edit.properties.chart_props.show_legends': 'Legenden anzeigen',
  'edit.properties.chart_props.settings': 'Einstellungen',

  'edit.properties.chart_title.placeholder': 'Diagrammtitel',
  'edit.properties.edit_data.error': 'keine Zahl',

  'edit.properties.x_axis.show_x_axis': 'X-Achse anzeigen',
  'edit.properties.x_axis.x_axis': 'X-Achse',
  'edit.properties.y_axis.show_y_axis': 'Y-Achse anzeigen',
  'edit.properties.y_axis.y_axis': 'Y-Achse',

  'edit.properties.form_choice.choice': 'Auswahl ',

  'edit.properties.form_props.question': 'Frage ',
  'edit.properties.form_props.description': 'Beschreibung',
  'edit.properties.form_props.choice_1': 'Auswahl 1',
  'edit.properties.form_props.choice_2': 'Auswahl 2',
  'edit.properties.form_props.choice_3': 'Auswahl 3',

  'edit.properties.form_question_option.add_choice': 'Auswahl hinzufügen',
  'edit.properties.form_question_rate.choose_icon': 'Symbolstil wählen',

  'edit.properties.device.header': 'Link',
  'edit.properties.device.change_device': 'Gerät ändern',
  'edit.properties.device.laptop': 'Laptop',
  'edit.properties.device.tablet': 'Tablet',
  'edit.properties.device.phone': 'Telefon',

  'edit.properties.chart.chart_data': 'Diagrammtyp:',

  'edit.properties.shape.placeholder': 'Form',
  'edit.properties.shape.distort': 'Verzerren',

  'edit.properties.ai_image.create': 'Mit AI erstellen',
  'edit.properties.ai_image.generate_with_ai': 'Ein Bild mit AI generieren',
  'edit.properties.ai_image.describe': 'Beschreiben Sie Ihre Anfrage an AI',
  'edit.properties.ai_image.generate': 'Generieren',

  'edit.properties.table.placeholder': 'Einstellungen',

  'edit.properties.text_size.placeholder': 'Mehrfach',
  'edit.properties.text_size.big_text': 'Großer Text',
  'edit.properties.text_size.title': 'Titel',
  'edit.properties.text_size.subtitle': 'Untertitel',
  'edit.properties.text_size.body': 'Text',
  'edit.properties.text_size.label': 'Etikett',

  'edit.properties.text_props.header': 'Text',
  'edit.properties.text_props.reset': 'Zurücksetzen',
  'edit.properties.text_props.fill': 'Füllen',
  'edit.properties.text_props.opacity': 'Transparenz',

  'edit.properties.video_props.video': 'Video',
  'edit.properties.video_props.paste':
    'Fügen Sie einen Youtube- oder Vimeo-Video-Link ein.',

  'edit.properties.website_props.website': 'Website',
  'edit.properties.website_props.paste': 'Fügen Sie eine Website-URL ein.',

  'edit.properties.alignment.position': 'Position',

  'edit.properties.list.list_type': 'Listentyp',
  'edit.properties.list.list_direction': 'Richtung',
  'edit.properties.list.media_position': 'Medienposition',
  'edit.properties.list.layout': 'Layout',
  'edit.properties.list.colors': 'Farben',
  'edit.properties.list.font_size': 'Schriftgröße',
  'edit.properties.list.size': 'Größe',
  'edit.properties.list.icon_frame': 'Symbolrahmen',
  'edit.properties.list.color.icon': 'Symbol',
  'edit.properties.list.color.icon_frame': 'Rahmen',
  'edit.properties.list.color.number': 'Nummer',
  'edit.properties.list.color.bullet': 'Kugel',
  'edit.properties.list.font_title': 'Titel',
  'edit.properties.list.color.title': 'Titel',
  'edit.properties.list.color.body': 'Text',
  'edit.properties.list.font_body': 'Text',
  'edit.properties.list.font_logo': 'Logo',

  'edit.properties.form.form_properties': 'Formulareigenschaften',
  'edit.properties.form.gdpr_message': 'GDPR-Nachricht',
  'edit.properties.form.submitting_required':
    'Formulareinreichung erforderlich',
  'edit.properties.form.consent': 'GDPR-Zustimmung aktivieren',
  'edit.properties.form.gdpr_tooltip':
    'Wenn dies aktiviert ist, erscheint ein Kontrollkästchen über der Sendeschaltfläche.',
  'edit.properties.form.gdpr_prop_placeholder':
    'Platzhalter für Freitexteingabe',
  'edit.properties.form.option_list_placeholder':
    'Platzhalter für Optionsliste',
  'edit.properties.form.rate_placeholder': 'Bewertungsplatzhalter',
  'edit.properties.form.back_button_text': 'Zurück-Schaltflächentext',
  'edit.properties.form.next_button_text': 'Weiter-Schaltflächentext',
  'edit.properties.form.submit_button_text': 'Senden-Schaltflächentext',
  'edit.properties.form.submit_message': 'Nachricht abschicken',
  'edit.properties.font.select_option': 'Option auswählen',

  'edit.add_new_slide_modal.slide_layouts': 'Folienlayouts',
  'edit.add_new_slide_modal.shared_with_me': 'Mit mir geteilt',
  'edit.add_new_slide_modal.custom_layouts': 'Benutzerdefinierte Layouts',
  'edit.add_new_slide_modal.search_slide_type':
    'Suchen Sie einen Folientyp aus Ihrer Layoutbibliothek, z. B. „Bild“',

  'edit.panel.menu_tabs.slide_notes': 'Foliennotizen',
  'edit.panel.menu_tabs.transcripts': 'Transkripte',
  'edit.panel.menu_tabs.tips': 'Tipps',
  'edit.panel.menu_tabs.qa': 'Q&A',
  'edit.panel.menu_tabs.themes': 'Themen',
  'edit.panel.menu_tabs.style': 'Stil',
  'edit.panel.menu_tabs.colors': 'Farben',

  'edit.panel.preferences': 'Einstellungen',
  'edit.panel.elements': 'Elemente',
  'edit.panel.notes': 'Notizen',
  'edit.panel.ai_assistant': 'AI-Assistent',
  'edit.panel.brand': 'Marke',

  'edit.slide_menu.warning': 'Warnung',
  'edit.slide_menu.warning_text':
    'Sie sind dabei, ein bestehendes Layout zu ersetzen. Möchten Sie fortfahren?',
  'edit.slide_menu.replace': 'Ersetzen',

  'edit.properties.zoom_types.fill': 'Füllen',
  'edit.properties.zoom_types.fit': 'Anpassen',
  'edit.properties.zoom_types.distort': 'Verzerren',
  'edit.properties.zoom_types.crop': 'Zuschneiden',

  'edit.definitions.bar_chart.display_name': 'Balkendiagramm',
  'edit.definitions.bar_chart.name1': 'Ingenieurwesen',
  'edit.definitions.bar_chart.name2': 'Marketing',
  'edit.definitions.bar_chart.name3': 'Betrieb',
  'edit.definitions.body.text': 'Neuer Text',
  'edit.definitions.body.display_name': 'Dies ist Ihr Text',
  'edit.definitions.bullet_list.display_name': 'Aufzählungsliste',
  'edit.definitions.bullet_list.item_title1': 'Element 1',
  'edit.definitions.bullet_list.item_title2': 'Element 2',
  'edit.definitions.bullet_list.item_title3': 'Element 3',
  'edit.definitions.bullet_list.item_body1': 'Beschreibung von Element 1',
  'edit.definitions.bullet_list.item_body2': 'Beschreibung von Element 2',
  'edit.definitions.bullet_list.item_body3': 'Beschreibung von Element 3',
  'edit.definitions.button.display_name': 'Schaltfläche',
  'edit.definitions.button.text': 'Schaltfläche',
  'edit.definitions.code.display_name': 'Code',
  'edit.definitions.device.display_name': 'Gerät',
  'edit.definitions.donut_chart.display_name': 'Donut-Diagramm',
  'edit.definitions.donut_chart.label': 'Serie1',
  'edit.definitions.donut_chart.value1': 'Alpha',
  'edit.definitions.donut_chart.value2': 'Bravo',
  'edit.definitions.donut_chart.value3': 'Charlie',
  'edit.definitions.donut_chart.value4': 'Delta',
  'edit.definitions.donut_chart.value5': 'Echo',
  'edit.definitions.form.display_name': 'Formular',
  'edit.definitions.form.title': 'Formulartitel',
  'edit.definitions.form.button_text': 'Senden',
  'edit.definitions.form.answers.text': 'Platzhalter für Texttyp',
  'edit.definitions.form.answers.option': 'Platzhalter für Optionstyp',
  'edit.definitions.form.answers.rate': 'Platzhalter für Bewertungstyp',
  'edit.definitions.form.buttons.next': 'Weiter',
  'edit.definitions.form.buttons.back': 'Zurück',
  'edit.definitions.form.submit': 'Danke',
  'edit.definitions.form.gdpr_consent_message': 'GDPR-Nachricht',
  'edit.definitions.form.question1': 'Frage 1',
  'edit.definitions.form.question2': 'Frage 2',
  'edit.definitions.form.question3': 'Frage 3',
  'edit.definitions.form.question2.description': 'Beschreibung',
  'edit.definitions.form.choice1': 'Auswahl 1',
  'edit.definitions.form.choice2': 'Auswahl 2',
  'edit.definitions.form.choice3': 'Auswahl 3',
  'edit.definitions.gif.display_name': 'Gif',
  'edit.definitions.icon.display_name': 'Symbol',
  'edit.definitions.icon_list.display_name': 'Symbolliste',
  'edit.definitions.icon_list.title1': 'Element 1',
  'edit.definitions.icon_list.body1': 'Beschreibung von Element 1',
  'edit.definitions.icon_list.title2': 'Element 2',
  'edit.definitions.icon_list.body2': 'Beschreibung von Element 2',
  'edit.definitions.icon_list.title3': 'Element 3',
  'edit.definitions.icon_list.body3': 'Beschreibung von Element 3',
  'edit.definitions.image.display_name': 'Bild',
  'edit.definitions.image_grid.display_name': 'Bildgitter',
  'edit.definitions.image_grid.title1': 'Element 1',
  'edit.definitions.image_grid.body1': 'Beschreibung von Element 1',
  'edit.definitions.image_grid.title2': 'Element 2',
  'edit.definitions.image_grid.body2': 'Beschreibung von Element 2',
  'edit.definitions.image_grid.title3': 'Element 3',
  'edit.definitions.image_grid.body3': 'Beschreibung von Element 3',
  'edit.definitions.image_list.display_name': 'Bildliste',
  'edit.definitions.image_list.title1': 'Element 1',
  'edit.definitions.image_list.body1': 'Beschreibung von Element 1',
  'edit.definitions.image_list.title2': 'Element 2',
  'edit.definitions.image_list.body2': 'Beschreibung von Element 2',
  'edit.definitions.image_list.title3': 'Element 3',
  'edit.definitions.image_list.body3': 'Beschreibung von Element 3',
  'edit.definitions.jumbo.text': 'Neuer Jumbo-Text',
  'edit.definitions.jumbo.display_name': 'Dies ist großer Text',
  'edit.definitions.label.text': 'Neues Etikett',
  'edit.definitions.label.display_name': 'Dies ist Ihr Etikett',
  'edit.definitions.line_chart.display_name': 'Liniendiagramm',
  'edit.definitions.line_chart.label1': 'Linie 1',
  'edit.definitions.line_chart.label1.name1': '09. Feb',
  'edit.definitions.line_chart.label1.name2': '10. Feb',
  'edit.definitions.line_chart.label1.name3': '11. Feb',
  'edit.definitions.line_chart.label1.name4': '12. Feb',
  'edit.definitions.line_chart.label1.name5': '13. Feb',
  'edit.definitions.line_chart.label2': 'Linie 2',
  'edit.definitions.line_chart.label2.name1': '09. Feb',
  'edit.definitions.line_chart.label2.name2': '10. Feb',
  'edit.definitions.line_chart.label2.name3': '11. Feb',
  'edit.definitions.line_chart.label2.name4': '12. Feb',
  'edit.definitions.line_chart.label2.name5': '13. Feb',
  'edit.definitions.links.display_name': 'Link',
  'edit.definitions.links.text': 'Schaltflächentext',
  'edit.definitions.logo.display_name': 'Logo',
  'edit.definitions.logo_list.display_name': 'Logoliste',
  'edit.definitions.logo_list.title1': 'Logo 1',
  'edit.definitions.logo_list.body1': 'Beschreibung von Logo 1',
  'edit.definitions.logo_list.title2': 'Logo 2',
  'edit.definitions.logo_list.body2': 'Beschreibung von Logo 2',
  'edit.definitions.logo_list.title3': 'Logo 3',
  'edit.definitions.logo_list.body3': 'Beschreibung von Logo 3',
  'edit.definitions.pie_chart.display_name': 'Kreisdiagramm',
  'edit.definitions.pie_chart.label': 'Serie 1',
  'edit.definitions.pie_chart.value1': 'Alpha',
  'edit.definitions.pie_chart.value2': 'Bravo',
  'edit.definitions.pie_chart.value3': 'Charlie',
  'edit.definitions.pie_chart.value4': 'Delta',
  'edit.definitions.pie_chart.value5': 'Echo',
  'edit.definitions.quote.display_name': 'Zitat',
  'edit.definitions.quote.quote': 'Zitattext',
  'edit.definitions.quote.author': 'Autor',
  'edit.definitions.rating.display_name': 'Bewertung',
  'edit.definitions.rating.title': 'Formulartitel',
  'edit.definitions.rating.button_text': 'Senden',
  'edit.definitions.rating.placeholder.text': 'Platzhalter für Texttyp',
  'edit.definitions.rating.placeholder.options': 'Platzhalter für Optionstyp',
  'edit.definitions.rating.placeholder.rate': 'Platzhalter für Bewertungstyp',
  'edit.definitions.rating.next': 'Weiter',
  'edit.definitions.rating.back': 'Zurück',
  'edit.definitions.rating.submit': 'Danke',
  'edit.definitions.rating.gdpr_consent': 'GDPR-Nachricht',
  'edit.definitions.rating.question_text': 'Bewerten Sie Ihre Erfahrung!',
  'edit.definitions.rating.question_description': 'Beschreibung',
  'edit.definitions.subtitle.text': 'Neuer Untertitel',
  'edit.definitions.subtitle.display_name': 'Dies ist Ihr Untertitel',
  'edit.definitions.showcase.display_name': 'Schaufenster',
  'edit.definitions.table.display_name': 'Tabelle',
  'edit.definitions.table.header1': 'Kategorien',
  'edit.definitions.table.header2': 'Werte 1',
  'edit.definitions.table.header3': 'Werte 2',
  'edit.definitions.table.header4': 'Werte 3',
  'edit.definitions.table.header5': 'Werte 4',
  'edit.definitions.table.row1.value1': 'Kategorien 1',
  'edit.definitions.table.row1.value2': '30',
  'edit.definitions.table.row1.value3': '40',
  'edit.definitions.table.row1.value4': '50',
  'edit.definitions.table.row1.value5': '60',
  'edit.definitions.table.row2.value1': 'Kategorien 2',
  'edit.definitions.table.row2.value2': '10',
  'edit.definitions.table.row2.value3': '20',
  'edit.definitions.table.row2.value4': '30',
  'edit.definitions.table.row2.value5': '40',
  'edit.definitions.table.row3.value1': 'Kategorien 3',
  'edit.definitions.table.row3.value2': '7',
  'edit.definitions.table.row3.value3': '23',
  'edit.definitions.table.row3.value4': '100',
  'edit.definitions.table.row3.value5': '70',
  'edit.definitions.table.row4.value1': 'Kategorien 4',
  'edit.definitions.table.row4.value2': '97',
  'edit.definitions.table.row4.value3': '2',
  'edit.definitions.table.row4.value4': '13',
  'edit.definitions.table.row4.value5': '55',
  'edit.definitions.timeline.display_name': 'Zeitstrahl',
  'edit.definitions.timeline.item1': 'Element 1',
  'edit.definitions.timeline.description1': 'Beschreibung von Element 1',
  'edit.definitions.timeline.item2': 'Element 2',
  'edit.definitions.timeline.description2': 'Beschreibung von Element 2',
  'edit.definitions.timeline.item3': 'Element 3',
  'edit.definitions.timeline.description3': 'Beschreibung von Element 3',
  'edit.definitions.title.text': 'Neuer Titel',
  'edit.definitions.title.display_name': 'Dies ist Ihr Titel',
  'edit.definitions.video.display_name': 'Video',
  'edit.definitions.website.display_name': 'Website',

  'edit.background.options': 'Hintergrundoptionen',

  'edit.color_mapping.swap_colors': 'Farben tauschen',
  'edit.color_mapping.restore': 'Zurücksetzen auf Standardfarben',
  // Edit page ends

  // User classify page
  'user_classify.business': 'Geschäft',
  'user_classify.continue_with_decktopus': 'Mit Decktopus fortfahren',
  'user_classify.customer_service': 'Kundenservice',
  'user_classify.education': 'Bildung',
  'user_classify.educator': 'Pädagoge',
  'user_classify.engineering': 'Ingenieurwesen',
  'user_classify.finance': 'Finanzen',
  'user_classify.friends': 'Freunde',
  'user_classify.google_search': 'Google-Suche',
  'user_classify.hr': 'Personalwesen',
  'user_classify.legal': 'Rechtlich',
  'user_classify.management': 'Management',
  'user_classify.other': 'Andere',
  'user_classify.product': 'Produkt',
  'user_classify.sales_marketing': 'Verkauf & Marketing',
  'user_classify.see_other_options': 'Andere Optionen ansehen',
  'user_classify.social_media': 'Soziale Medien',
  'user_classify.student': 'Student',
  'user_classify.tell_us_about_yourself': 'Erzählen Sie uns von sich',
  'user_classify.classify_question': 'Zu welchem Zweck nutzen Sie Decktopus?',
  'user_classify.business_question': 'Was für eine Arbeit machen Sie?',
  'user_classify.how_to_hear_about_us': 'Wie haben Sie von uns erfahren?',
  // User classify page ends

  // Share modal
  'share_modal.embed.code': 'Einbettungscode erhalten',
  'share_modal.share_link': 'Link teilen',
  'share_modal.get_share_link': 'Teilungslink erhalten',
  'share_modal.make_the_share_link_public':
    'Machen Sie den Teilungslink öffentlich',
  'share_modal.select_from_list': 'Aus Benutzerliste auswählen',

  'share_modal.share_link_updated': 'Teilungslink erfolgreich aktualisiert.',
  'share_modal.invite_people_to_collaborate':
    'Laden Sie Personen zur Zusammenarbeit ein',
  'share_modal.invitations_sent': 'Einladungen gesendet',
  'share_modal.organization_deck_permission': 'Organisationsberechtigung',
  'share_modal.valid_email_message':
    'Bitte geben Sie eine gültige E-Mail-Adresse ein und drücken Sie die Eingabetaste',
  'share_modal.add_collaborators': 'Mitarbeiter hinzufügen',
  'share_modal.not_collaborating_message':
    'Sie arbeiten noch mit niemandem zusammen.',
  'share_modal.collaborators': 'Mitarbeiter',
  'share_modal.can_edit': 'Kann bearbeiten',
  'share_modal.can_view': 'Kann anzeigen',
  'share_modal.embed_code': 'Einbettungscode',
  'share_modal.collaborated_users_updated':
    'Kollaborierte Benutzer aktualisiert',
  'share_modal.save_complete': 'Änderungen sind gespeichert',
  'share_modal.pdf_export': 'PDF exportieren',
  'share_modal.pptx_export': 'PPTX exportieren',
  'share_modal.latest_exports': 'Meine neuesten Exporte',

  'share_modal.custom_domain.status': 'Status',
  'share_modal.custom_domain.host': 'Host',
  'share_modal.custom_domain.completed': 'ABGESCHLOSSEN',
  'share_modal.custom_domain.pending': 'AUSSTEHEND',
  'share_modal.custom_domain.record': 'Aufzeichnung',
  'share_modal.custom_domain.data': 'Daten',
  'share_modal.custom_domain.cname': 'CNAME',
  'share_modal.custom_domain.share_link_placeholder':
    'z. B. https://dokument.ihredomain.com',
  'share_modal.custom_domain.domain_successfully':
    'Domäne erfolgreich gespeichert.',
  'share_modal.custom_domain.domain_deleted': 'Domäne erfolgreich gelöscht.',
  'share_modal.custom_domain.domain_failed':
    'Die Domäne wurde noch nicht verifiziert. Abhängig von Ihrem Domainserver kann dies bis zu 72 Stunden dauern.',
  'share_modal.custom_domain.dns_record':
    'Um Ihre benutzerdefinierte Domäne mit Ihrem Decktopus zu verbinden, müssen Sie Ihre DNS-Einträge anhand der untenstehenden Tabelle aktualisieren.',
  'share_modal.custom_domain.add_your_subdomain':
    'Fügen Sie Ihre Subdomäne hinzu:',
  'share_modal.custom_domain.info': 'Info',
  'share_modal.custom_domain.verified': 'Verifiziert',
  'share_modal.custom_domain.unverified': 'Nicht verifiziert',
  'share_modal.custom_domain.connect_your_custom_domain':
    'Verbinden Sie Ihre benutzerdefinierte Domäne',
  // Share modal ends

  // Admin page
  'admin.menu.user_info': 'Benutzerinformationen',
  'admin.menu.back_to_search': 'Zurück zur Suche',
  'admin.search.welcome_support_panel':
    'Willkommen im <strong>Support-Panel</strong>',
  'admin.search.you_can_search_by':
    'Sie können nach E-Mail, Deck-ID oder Freigabeschlüssel suchen, um das Gewünschte aufzulisten.',
  'admin.search.share_key': 'Freigabeschlüssel',
  'admin.search.deck_id': 'Deck-ID',
  'admin.search.user_not_found': 'Benutzer nicht gefunden.',
  'admin.table.e_mail': 'E-Mail',
  'admin.table.delete_user': 'Benutzer löschen',
  'admin.table.created_date': 'Erstellungsdatum',
  'admin.confirm.cannot_undo_delete_user':
    'Sie können diese Funktion nicht rückgängig machen. Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
  'admin.table.actions': 'Aktionen',
  // Admin page ends

  // Table component menu items
  'table.add_row_above': 'Zeile oberhalb hinzufügen',
  'table.add_row_below': 'Zeile unterhalb hinzufügen',
  'table.add_column_left': 'Spalte links hinzufügen',
  'table.add_column_right': 'Spalte rechts hinzufügen',
  'table.delete_row': 'Zeile löschen',
  'table.delete_column': 'Spalte löschen',
  // Table component menu items ends

  // Languages
  'language.tr': 'Türkisch',
  'language.en': 'Englisch',
  'language.de': 'Deutsch',
  // Languages ends

  // AI Loading tips
  'ai_loading.text1':
    'Wusstest du, dass Menschen sich nur 10 % von dem merken, was sie nach drei Tagen hören, aber 65 % behalten, wenn ein Bild hinzugefügt wird?',
  'ai_loading.text2':
    'Im Durchschnitt treffen Menschen innerhalb der ersten 90 Sekunden eine Entscheidung über eine Präsentation.',
  'ai_loading.text3':
    'Visuelle Hilfsmittel in einer Präsentation können das Lernen um bis zu 400 % verbessern.',
  'ai_loading.text4':
    'Die längste Präsentation aller Zeiten dauerte 124 Stunden und wurde 2018 von Arvind Mishra in Indien gehalten.',
  'ai_loading.text5':
    'Die Verwendung von Farben mit hohem Kontrast in Ihren Folien kann die Lesbarkeit um bis zu 78 % erhöhen.',
  'ai_loading.text6':
    'Wusstest du schon? 55 % der Kommunikation bestehen aus Körpersprache, 38 % aus dem Tonfall und nur 7 % aus den tatsächlich gesprochenen Worten.',
  'ai_loading.text7':
    'Die durchschnittliche Aufmerksamkeitsspanne eines Menschen beträgt nur 8 Sekunden – machen Sie Ihre Folien wirkungsvoll!',
  'ai_loading.text8':
    'Kommunikationstipp: Verwenden Sie Storytelling, um Ihre Präsentationen einprägsamer zu machen.',
  'ai_loading.text9':
    'Präsentationen mit einfacheren Folien sind in der Regel wirkungsvoller.',
  'ai_loading.text10':
    'Das Einbinden von Geschichten in Ihre Präsentation kann sie 22-mal einprägsamer machen.',
  'ai_loading.text11':
    'Augenkontakt mit Ihrem Publikum kann das Engagement und das Vertrauen erhöhen.',
  // AI Loading tips
}
