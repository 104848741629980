import { texts } from './texts'
import { planFeatures } from './planFeatures'
import { errors } from './errors'
import { apiEnums } from './apiEnums'
import { TranslationTypes } from '../en'

const deutchTranslations: TranslationTypes = {
  ...texts,
  ...planFeatures,
  ...errors,
  ...apiEnums,
  '': '',
  // TODO: move translations below to the texts.ts file after German translation completed.
  'common.actions.search': 'Suchen',
  'common.actions.select': 'Wählen',
  'common.actions.preview': 'Vorschau',
  'common.informative.large_media_file':
    'Hoppla! Ihre Mediendatei ist über 5 MB groß. Bitte wählen Sie für ein reibungsloseres Erlebnis eine kleinere Datei aus.',
  'common.informative.captcha_error':
    'Die Captcha-Verifizierung ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
  'common.informative.captcha_expired':
    'Captcha abgelaufen, bitte versuchen Sie es erneut',
  'common.informative.captcha_challange_expired':
    'Captcha-Herausforderung abgelaufen, bitte versuchen Sie es erneut.',
  'common.deck.undo': 'Rückgängig machen',
  'login.welcome': 'Willkommen!',
  'login.title1': '2 Mn',
  'login.text1': 'Benutzer auf der ganzen Welt',
  'login.title2': '64%',
  'login.text2': 'Zeitersparnis durch die Erstellung von Präsentationen mit AI',
  'login.title3': '412k',
  'login.text3':
    'Durchschnittliche Präsentationen, die mit Decktopus erstellt wurden',
  'login.title4': '<1 min',
  'login.text4': 'Durchschnittliche Reaktionszeit des Kundensupports',
  'login.incorrect_provider_jwt': `Sie haben sich mit E-Mail und Passwort registriert. Bitte melden Sie sich mit diesen Informationen an, anstatt sich mit Google anzumelden.`,
  'login.incorrect_provider_google': `Sie haben sich mit Ihrem Google-Konto registriert. Bitte melden Sie sich mit Google an, anstatt sich mit E-Mail und Passwort anzumelden.`,
  'signup.banner': 'Nur noch ein Klick bis zur Präsentation',
  'init.use_this_template': 'Verwenden Sie diese Vorlage',
  'init.category0': 'Alle',
  'init.category1': 'Geschäft',
  'init.category2': 'Vorschlag',
  'init.category3': 'Online-Kurs',
  'init.category4': 'Ausbildung',
  'init.category5': 'Gemeinschaft',
  'init.category6': 'Ernährung',
  'init.category7': 'Zusammenfassung',
  'init.category8': 'Fallstudie',
  'init.category9': 'Startup Pitch',
  'subscriptions.update_payment': 'Zahlungsmethode aktualisieren',
  'dashboard.card.publish_template': 'Vorlage veröffentlichen',
  'dashboard.card.unpublish_template': 'Veröffentlichung der Vorlage aufheben',
  'dashboard.publish.success': 'Ihr Deck wurde erfolgreich veröffentlicht',
  'dashboard.unpublish.success':
    'Die Veröffentlichung Ihres Decks wurde erfolgreich aufgehoben',
  'dashboard.publish.error': 'Deck konnte nicht veröffentlicht werden',
  'dashboard.unpublish.error': 'Deck konnte nicht unveröffentlicht werden',
  'edit.component_fix.detail':
    'An Ihrem Deck werden letzte Anpassungen vorgenommen.',
  'edit.component_fix.info': 'Bitte warten',
  'edit.media.modal.image.unsplash_library': 'Unsplash-Bibliothek ',
  'edit.media.modal.image.title':
    'Wählen Sie ein Bild aus der <0>Unsplash-Bibliothek</0>',
  'edit.canvas.blank': 'Leer',
  'edit.canvas.context_menu.edit_with_ai': 'Mit AI bearbeiten',
  'edit.canvas.context_menu.edit_with_ai.summarize': 'Zusammenfassen',
  'edit.canvas.context_menu.edit_with_ai.fix': 'Fix',
  'edit.canvas.context_menu.edit_with_ai.rewrite': 'Umschreiben',
  'edit.canvas.context_menu.edit_with_ai.extend': 'Verlängern',
  'edit.canvas.context_menu.edit_with_ai.other': 'Andere',
  'edit.canvas.context_menu.edit_with_ai.generate': 'Erzeugen',
  'edit.canvas.context_menu.edit_with_ai.prompt_placeholder':
    'Geben Sie hier Ihre Eingabeaufforderung ein.',
  'edit.canvas.context_menu.edit_with_ai.title':
    'Geben Sie Decktopus eine Aufforderung, Ihre ausgewählten Inhalte zu verbessern',
  'edit.canvas.context_menu.edit_with_ai.input_error_message':
    'Die Beschreibung darf nicht kürzer als 3 Zeichen sein.',
  'edit.canvas.context_menu.edit_with_ai.nothing_improved':
    'Es gab nichts zu reparieren',
  'edit.preferences.background_tooltip':
    'Wählen Sie unten ein neues Dekor aus, um den Hintergrund zu ändern.',
  'edit.properties.quote.alignment': 'Angebotsausrichtung',
  'edit.properties.quote.size.label.text': 'Zitattext',
  'edit.properties.quote.size.label.author': 'Autorin oder Autor',
  'edit.properties.media.image_opacity': 'Opazität',
  'edit.properties.media.corner_radius': 'Eckradius',
  'edit.color_mapping.restore_short': 'Farben wiederherstellen',
  'edit.color_mapping.texts': 'Texte',
  'edit.color_mapping.texts.item1': 'Großer Text',
  'edit.color_mapping.texts.item2': 'Titel',
  'edit.color_mapping.texts.item3': 'Untertitel',
  'edit.color_mapping.texts.item4': 'Körper',
  'edit.color_mapping.texts.item5': 'Etikett',
  'edit.color_mapping.background': 'Hintergrund',
  'edit.color_mapping.quote': 'Zitat',
  'edit.color_mapping.quote.item1': 'Zitat',
  'edit.color_mapping.quote.item2': 'Autorin oder Autor',
  'edit.color_mapping.icons': 'Symbole',
  'edit.color_mapping.icons.item1': 'Symbol',
  'edit.color_mapping.icons.item2': 'Symbolfüllung',
  'edit.color_mapping.lists': 'Listen',
  'edit.color_mapping.lists.item1': 'Aufzählungszeichen',
  'edit.color_mapping.lists.item2': 'Listentitel',
  'edit.color_mapping.lists.item3': 'Listentext',
  'edit.color_mapping.table': 'Tisch',
  'edit.color_mapping.table.item1': 'Inhalt',
  'edit.color_mapping.table.item2': 'Highlight',
  'edit.color_mapping.buttons': 'Schaltflächen',
  'edit.color_mapping.buttons.item1': 'Text',
  'edit.color_mapping.buttons.item2': 'Schaltflächenfüllung',
  'edit.color_mapping.buttons.item3': 'Border',
  'edit.color_mapping.timeline': 'Timeline',
  'edit.color_mapping.timeline.item1': 'Timeline Bar',
  'edit.color_mapping.timeline.item2': 'Timeline Dot',
  'edit.color_mapping.form': 'Form',
  'edit.color_mapping.form.item1': 'Question',
  'edit.color_mapping.form.item2': 'Button Text',
  'edit.color_mapping.form.item3': 'Schaltflächenfüllung',
  'edit.color_mapping.form.item4': 'Symbol',
  'edit.color_mapping.chart': 'Diagramm',
  'edit.color_mapping.chart.items': 'Farbe',
  'edit.color_mapping.text_highlight': 'Texthervorhebung',
  'edit.color_mapping.text_highlight.item1': 'Highlight',
  'edit.color_mapping.text_highlight.item2': 'Hintergrund hervorheben',
  'edit.color_mapping.shape': 'Form',
  'edit.color_mapping.shape.item1': 'Formfüllung',
  'edit.color_mapping.decoration': 'Dekorationen',
  'edit.color_mapping.decoration.items': 'Dekoration',
  'admin.action_menu.login_as_user': 'Als Benutzer anmelden',
  'admin.dashboard.header.back_to_support': 'Zurück zum Supportbereich',
  'admin.dashboard.header.logged_in_as_user':
    'Sie haben sich als Benutzer angemeldet.',
  'payment_confirmation.title':
    'Aktualisieren Sie Ihren Plan auf {{ planName }}',
  'payment_confirmation.subtitle':
    'Sie sind nur einen Schritt davon entfernt, die exklusiven Funktionen von Decktopus zu genießen',
  'payment_confirmation.plan': 'Planen:',
  'payment_confirmation.payment_method': 'Zahlungsmethode:',
  'payment_confirmation.card_number': 'Kartennummer:',
  'payment_confirmation.card_date': 'Kartendatum:',
  'payment_confirmation.total': 'Gesamt:',
  'payment_confirmation.contact_us':
    'Wenn Sie Fragen haben oder Hilfe benötigen, <0>können Sie uns jederzeit kontaktieren.</0>',
  'payment_confirmation.cancel': 'Zahlung stornieren',
  'payment_confirmation.confirm': 'Bestätigen und bezahlen',
  'payment_confirmation.seat': 'Sitz',
  'payment_confirmation.pro': 'Pro AI',
  'payment_confirmation.business': 'Business AI',
  'payment_confirmation.remaining_line':
    'Verbleibende Zeit auf {{ planName }} nach {{ date }}',
  'payment_confirmation.unused_line':
    'Nicht genutzte Zeit auf {{ planName }} nach {{ date }}',
  'payment_confirmation.total_tooltip':
    'Die Zahlung wird anteilig auf Grundlage der verbleibenden Tage Ihres aktuellen Abrechnungszeitraums berechnet.',
}

export default deutchTranslations
