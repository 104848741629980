import { RefObject, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CanvasRectEvent, CanvasRectEventName } from 'src/events'
import { RootState, setSelectedComponents } from 'src/store'
import { ComponentSchema } from 'src/types/api/responseObjects'
import { useFlags } from './useFlags'

export const useUnSelect = (
  dataAttr: string,
  ref: RefObject<HTMLDivElement>,
  selectionRef: RefObject<HTMLDivElement>,
) => {
  const flags = useFlags()
  const dispatch = useDispatch()
  const [canvasRect, setCanvasRect] = useState<DOMRect>()
  const [canvasScale, setCanvasScale] = useState(1)

  const [isDragging, setIsDragging] = useState(false)
  const [startPosition, setStartPosition] = useState<{
    x: number
    y: number
  } | null>(null)

  const { components } = useSelector(({ edit }: RootState) => ({
    components: edit.activeSlideData.data
      ?.find(({ id }) => id === edit.activeSlideDataID)
      ?.slideDataComponents.map(({ component }) => component),
  }))

  useEffect(() => {
    const listener = (e: CanvasRectEvent) => {
      setCanvasRect(e.detail.rect)
      setCanvasScale(e.detail.scale)
    }

    document.addEventListener(CanvasRectEventName, listener as EventListener)

    return () => {
      document.removeEventListener(
        CanvasRectEventName,
        listener as EventListener,
      )
    }
  }, [])

  const hasParentWithAttribute = useCallback(
    (target: HTMLElement, dataAttr: string): boolean => {
      if (target?.tagName === 'BODY') {
        return false
      }

      if (target?.getAttribute(dataAttr)) {
        return true
      }

      return hasParentWithAttribute(
        target?.parentElement as HTMLElement,
        dataAttr,
      )
    },
    [],
  )

  const handleMouseMove = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (isDragging && startPosition && selectionRef.current) {
        const currentPosition = 'touches' in event ? event.touches[0] : event
        const { clientX, clientY } = currentPosition

        // Calculate rectangle dimensions and position
        const left = Math.min(clientX, startPosition.x)
        const top = Math.min(clientY, startPosition.y)
        const width = Math.abs(clientX - startPosition.x)
        const height = Math.abs(clientY - startPosition.y)

        // Update selectionRef styles
        const selectionDiv = selectionRef.current
        if (selectionDiv) {
          selectionDiv.style.display = 'block'
          selectionDiv.style.left = `${left}px`
          selectionDiv.style.top = `${top}px`
          selectionDiv.style.width = `${width}px`
          selectionDiv.style.height = `${height}px`
        }
        // Inside handleMouseMove function after calculating the overlap
        if (canvasRect) {
          const overlapLeft = Math.max(left, canvasRect.x)
          const overlapTop = Math.max(top, canvasRect.y)
          const overlapRight = Math.min(
            left + width,
            canvasRect.x + canvasRect.width,
          )
          const overlapBottom = Math.min(
            top + height,
            canvasRect.y + canvasRect.height,
          )

          const overlapWidth = Math.max(0, overlapRight - overlapLeft)
          const overlapHeight = Math.max(0, overlapBottom - overlapTop)

          if (overlapWidth > 0 && overlapHeight > 0) {
            // Calculate overlap position relative to canvasRect
            const relativeX = (overlapLeft - canvasRect.x) / canvasScale
            const relativeY = (overlapTop - canvasRect.y) / canvasScale
            const relativeWidth = overlapWidth / canvasScale
            const relativeHeight = overlapHeight / canvasScale

            // Check if the overlapping rectangle is touching any components
            const selectedComponents: ComponentSchema[] =
              components?.filter((component) => {
                const { x, y, width = 0, height = 0 } = component.positions

                const componentLeft = x
                const componentTop = y
                const componentRight = x + (width || 0)
                const componentBottom = y + (height || 0)

                // Check for intersection
                return (
                  relativeX < componentRight &&
                  relativeX + relativeWidth > componentLeft &&
                  relativeY < componentBottom &&
                  relativeY + relativeHeight > componentTop
                )
              }) || []

            // Dispatch selected components
            dispatch(
              setSelectedComponents(
                selectedComponents.map((component: ComponentSchema) => ({
                  id: component.id || undefined,
                  tempId: component.tempId || undefined,
                  type: component.type,
                })),
              ),
            )
          }
        }
      }
    },
    [isDragging, startPosition, selectionRef],
  )

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
    setStartPosition(null)

    // Hide the selection rectangle
    if (selectionRef.current) {
      selectionRef.current.style.display = 'none'
      selectionRef.current.style.display = 'block'
      selectionRef.current.style.left = `0`
      selectionRef.current.style.top = `0`
      selectionRef.current.style.width = `0`
      selectionRef.current.style.height = `0`
    }
  }, [selectionRef])

  const dragWrapperListener = useCallback(
    (event: Event) => {
      if (
        hasParentWithAttribute(event.target as HTMLElement, 'data-canvas') &&
        !hasParentWithAttribute(event.target as HTMLElement, dataAttr)
      ) {
        dispatch(setSelectedComponents())

        // Start dragging
        setIsDragging(true)

        const startPosition =
          'touches' in event
            ? (event as TouchEvent).touches[0]
            : (event as MouseEvent)
        setStartPosition({ x: startPosition.clientX, y: startPosition.clientY })

        // Show the selection rectangle
        if (selectionRef.current) {
          selectionRef.current.style.display = 'block'
        }
      }
    },
    [dataAttr, dispatch, hasParentWithAttribute, selectionRef],
  )

  useEffect(() => {
    const currentRef = ref.current

    if (currentRef) {
      currentRef.addEventListener('mousedown', dragWrapperListener)
      currentRef.addEventListener('touchstart', dragWrapperListener)

      if (flags.FE_539_MULTIPLE_SELECTION) {
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('touchmove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
        document.addEventListener('touchend', handleMouseUp)
      }
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('mousedown', dragWrapperListener)
        currentRef.removeEventListener('touchstart', dragWrapperListener)

        if (flags.FE_539_MULTIPLE_SELECTION) {
          document.removeEventListener('mousemove', handleMouseMove)
          document.removeEventListener('touchmove', handleMouseMove)
          document.removeEventListener('mouseup', handleMouseUp)
          document.removeEventListener('touchend', handleMouseUp)
        }
      }
    }
  }, [ref, dragWrapperListener, handleMouseMove, handleMouseUp])
}
